<template>
  <div>
    <div class="top_area">
      <div class="container">
        <div class="brand_logo text-center">
          <img width="265" src="../assets/logo.png" />
        </div>
      </div>
    </div>
    <nav
      class="navbar navbar-expand-lg navbar-light bg-transparent custom_navbar nav_purple"
    >
      <div class="container-fluid">
        <!-- <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button> -->
        <div class="navbar-collapse" id="navbarNav2">
          <ul class="navbar-nav mx-auto">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#"
                ><img width="18px" src="@/assets/gift.png" /> Points needed to
                unlock gift</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#"
                ><img width="18px" src="@/assets/reward_points.png" /> Total
                Reward points</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#"
                ><img width="24px" src="@/assets/rank.png" /> Rank Status</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" tabindex="-1" aria-disabled="true"
                ><img width="18px" src="@/assets/rank.png" /> Points needed to
                complete the course</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  name: "AppHeader",
  components: {},
};
</script>
