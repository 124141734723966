<template>
  <div class="login bg_9 h_100 position-relative">
    <div class="container">Find Out More</div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import AppFooter from "@/components/AppFooter.vue";

export default {
  name: "FindOutMoreView",
  components: { AppFooter },
};
</script>
