<template>
  <div class="bg_8 h_100 position-relative">
    <AppHeader></AppHeader>

    <div class="container">
      <div class="">
        <div
          class="col-sm-12 col-lg-12 col-xl-10 col-xxl-8 col-12 col-md-12 mx-auto burger"
        >
          <div class="text-center mb-5">
            <h1 class="heading_1">STORY TIME</h1>
          </div>
          <div class="row justify-content-center">
            <div
              class="col-md-4 col-sm-4"
              v-for="storytime in storytimes"
              :key="storytime.id"
            >
              <div class="img_list">
                <h3 class="heading_sm_1">{{ storytime.name }}</h3>
                <div class="img">
                  <img src="@/assets/img_1.jpg" alt="img" />
                </div>
                <router-link
                  class="btn btn-warning btn-sm font_FredokaOne"
                  :to="{
                    name: 'story-exercise-' + storytime.currentNode,
                    params: {
                      lesson_id: this.$route.params.lesson_id,
                      storytime_id: storytime.id,
                    },
                  }"
                  v-if="!storytime.locked"
                  >Start <img src="@/assets/audio.png" alt="audio"
                /></router-link>
                <a
                  v-else
                  class="btn btn-warning btn-sm font_FredokaOne"
                  disabled="true"
                >
                  Start <img src="@/assets/audio.png" alt="audio"
                /></a>
              </div>
            </div>
            <!-- <div class="col-md-3 col-sm-4">
              <div class="img_list">
                <h3 class="heading_sm_1">Story 1</h3>
                <div class="img">
                  <img src="@/assets/img_1.jpg" alt="img" />
                </div>
                <a href="#" class="btn btn-warning btn-sm font_FredokaOne"
                  >Start <img src="@/assets/audio.png" alt="audio"
                /></a>
              </div>
            </div> -->
            <!-- <div class="col-md-3 col-sm-4">
              <div class="img_list">
                <h3 class="heading_sm_1">Story 1</h3>
                <div class="img">
                  <img src="@/assets/img_2.jpg" alt="img" />
                </div>
                <a href="#" class="btn btn-warning btn-sm font_FredokaOne"
                  >Start <img src="@/assets/audio.png" alt="audio"
                /></a>
              </div>
            </div> -->
            <!-- <div class="col-md-3 col-sm-4">
              <div class="img_list">
                <h3 class="heading_sm_1">Story 1</h3>
                <div class="img">
                  <img src="@/assets/img_3.jpg" alt="img" />
                </div>
                <a href="#" class="btn btn-warning btn-sm font_FredokaOne"
                  >Start <img src="@/assets/audio.png" alt="audio"
                /></a>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
import StoryTimeService from "../services/story-time.service.js";
export default {
  name: "StorysView",
  components: { AppFooter, AppHeader },
  data() {
    return {
      loading: true,
      storytimes: false,
    };
  },
  mounted() {
    StoryTimeService.getStoryTime(this.$route.params.lesson_id).then(
      (response) => {
        // console.log(response);
        this.storytimes = response.data.storytimes;
        this.storytimes.forEach((element) => {
          var currentNode = 1;
          if (element.remaining_exercises.length > 0) {
            currentNode = element.current_exercise;
          } else if (element.completed_exercises.length > 0) {
            currentNode = 1;
          }
          element.currentNode = currentNode;
        });
        // console.log(this.storytimes);
        this.loading = false;
      }
    );
  },
};
</script>
