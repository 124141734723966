<template>
  <div class="bg_8 h_100 position-relative">
    <AppHeader></AppHeader>
    <div class="container">
      <div class="">
        <div
          class="col-sm-12 col-lg-12 col-xl-10 col-xxl-8 col-12 col-md-12 mx-auto burger"
        >
          <div class="text-center mb-5">
            <h1 class="heading_1">LEARN SOMETHING NEW</h1>
          </div>
          <DialogPopup :modal="true" v-model:visible="explanationDialog">
            <div class="quiz_layout_popup">
              <Splide
                v-if="explanationType == 'image'"
                :options="{ rewind: true }"
                aria-label="My Favorite Images"
              >
                <SplideSlide v-for="image in explanationImages" :key="image">
                  <!-- <img src="@/assets/bg/bg_8.jpg" alt="Sample 1" /> -->
                  <img :src="image.url" alt="NA" v-if="image.type == 'image'" />
                  <video
                    :src="image.url"
                    v-else-if="image.type == 'video'"
                    controls
                  >
                    <source :src="image.url" type="video/mp4" />
                  </video>
                </SplideSlide>
              </Splide>
              <iframe
                v-else
                :src="explanationSrc"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </DialogPopup>
          <div class="row justify-content-center">
            <div
              v-for="explanation in explanations"
              :key="explanation.id"
              class="col-md-4 col-sm-4 col-12"
            >
              <div class="video_list">
                <h3 class="heading_sm_1">{{ explanation.name }}</h3>
                <div class="video">
                  <a
                    href="javascript:;"
                    @click="openExplanation(explanation.id)"
                  >
                    <img
                      v-if="explanation.image == ''"
                      width=""
                      src="../assets/noimage.png"
                    />
                    <img v-else width="" :src="explanation.image" />
                  </a>
                </div>
                <router-link
                  v-if="!explanation.locked"
                  :to="{
                    name: 'quiz',
                    params: {
                      quiz_id: explanation.id,
                    },
                  }"
                  class="btn btn-warning btn-sm font_FredokaOne"
                >
                  Quiz Time
                </router-link>
                <a
                  v-else
                  href="javascript:;"
                  class="btn btn-warning btn-sm font_FredokaOne"
                >
                  Not available yet
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>
<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import ExplanationService from "../services/explanation.service";
import "@splidejs/vue-splide/css";
export default {
  name: "ExplanationsView",
  components: {
    AppHeader,
    AppFooter,
    Splide,
    SplideSlide,
  },
  data() {
    return {
      loading: false,
      explanations: null,
      explanationType: "image",
      explanationDialog: false,
      explanationImages: null,
      explanationSrc: null,
    };
  },
  mounted() {
    console.log(this.$route.params.lesson_id);
    ExplanationService.getExplanations(this.$route.params.lesson_id).then(
      (response) => {
        this.explanations = response.data.explanations;
      }
    );
  },
  methods: {
    openExplanation(explanationId) {
      let explanation = this.explanations.filter((explanation) => {
        return explanation.id == explanationId;
      });
      explanation = explanation[0];
      ExplanationService.getExplanationsContent(explanation.id).then(
        (response) => {
          // console.log(response);
          this.explanationType = "image";
          if (this.explanationType == "image") {
            this.explanationSrc = null;
            this.explanationImages = response.data.content;
          } else {
            this.explanationImages = null;
            this.explanationSrc = response.data.content;
            //this.explanationSrc = "https://www.youtube.com/embed/Wlf1T5nrO50";
          }
        }
      );
      this.explanationDialog = true;
    },
  },
};
</script>
