<template>
  <div class="lessons_2 bg_1 position-relative h_100">
    <AppHeader></AppHeader>
    <div class="container">
      <div class="">
        <div class="heading-lg justify-content-center d-flex text-center">
          <h1><img width="63" src="../assets/pencil.png" />Lessons</h1>
          <!-- <p>{{ $t("hello") }}</p> -->
        </div>
        <div class="sky_section">
          <div
            class="col-sm-11 col-lg-8 col-xl-8 col-xxl-8 col-10 col-md-8 mx-auto"
          >
            <div class="row">
              <Suspense>
                <template #default>
                  <LessonCard :unit_id="this.$route.params.unit_id" />
                </template>
                <template #fallback>
                  <AppLoading />
                </template>
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
import AppLoading from "@/components/AppLoading.vue";
import LessonCard from "@/components/LessonCard.vue";
import LessonService from "../services/lesson.service";
import UnitService from "../services/unit.service";
export default {
  name: "LessonsView",
  components: { AppFooter, AppHeader, AppLoading, LessonCard },
  data() {
    return {
      loading: false,
      lessons: null,
    };
  },
  mounted() {
    console.log(this.$route.params.unit_id);
    // var data = {};
    // data["type"] = "lesson";
    // data["id"] = this.$route.params.unit_id;
    // data["action"] = "unlock";
    let check = 0;
    LessonService.getLessons(this.$route.params.unit_id).then((response) => {
      let tt = response.data.lessons;
      tt.forEach((element) => {
        if (element.locked == false && element.completed == true) {
          check++;
        }
      });
      var data = {};
      if (check == tt.length) {
        data["type"] = "unit";
        data["id"] = this.$route.params.unit_id;
        data["action"] = "unlock";
        UnitService.getUnlockNextUnit(data).then((response) => {
          console.log("My Respo", response);
        });
        console.log("Unlock Next");
      } else {
        console.log("Not Unlock Next");
      }
    });
  },
};
</script>
