<template>
  <div class="course_page bg_7 h_100 position-relative">
    <div class="top_area">
      <div class="container">
        <div class="brand_logo text-center">
          <img width="265" src="../assets/logo.png" />
        </div>
      </div>
    </div>
    <div class="container">
      <div
        class="questionContainer result-feedback col-lg-9 col-xl-8 col-md-12 col-sm-12 col-12 mx-auto pt-4"
        v-if="congratsDialog"
      >
        <div class="col-9 m-auto">
          <div class="text-center ribbon-award">
            <img src="@/assets/ribbon-award.png" />
          </div>
          <div class="row">
            <div class="col-md-6 answers_number">
              <h4 class="text-success">
                <span>{{ this.correctAnswers }}</span> Correct Answers
              </h4>
              <h4 class="text-danger">
                <span>{{ this.incorrectAnswers }}</span> Incorrect Answers
              </h4>
            </div>
            <div
              class="col-md-6 flex-column align-items-end d-flex result_title"
            >
              <h6 class="">Your Result</h6>
              <h1 class="text-success">{{ this.percentage }} %</h1>
            </div>
          </div>
          <div class="text-center">
            <button
              v-if="tryAgainButton"
              class="btn btn-success"
              @click="$router.go($router.currentRoute)"
            >
              Try Again For 100%
            </button>
            <button v-else class="btn btn-success" @click="$router.back()">
              Go To The Next Exercise
            </button>
          </div>
        </div>
      </div>
      <div
        class="questionContainer col-lg-9 col-xl-8 col-md-12 col-sm-12 col-12 mx-auto pt-4"
        v-else
      >
        <div v-if="loading"><AppLoading /></div>
        <div>
          <h2 class="titleContainer title mb-4 h3 text-dark text-center">
            {{ questions[activeQuestionIndex]?.text }}
          </h2>
          <div class="optionContainer">
            <div
              v-for="answer in questions[activeQuestionIndex]?.answers"
              :key="answer.answer_id"
              class="option"
              :class="this.answerId == answer.answer_id ? 'is-selected' : ''"
              @click="this.answerId = answer.answer_id"
            >
              {{ answer.text }}
            </div>
          </div>
          <div class="mx-3 text-center">
            <button
              v-if="questions"
              class="btn texture_btn btn-success btn-sm circle_texture text-black mt-4"
              @click="checkAnswer()"
            >
              <span>{{
                activeQuestionIndex + 1 == questions.length ? "Submit" : "Next"
              }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
  <DialogPopup
    :closable="false"
    :modal="true"
    v-model:visible="congratsDialog1"
  >
    Congrats
  </DialogPopup>
</template>

<script>
import AppLoading from "@/components/AppLoading.vue";
import AppFooter from "@/components/AppFooter.vue";
import QuizService from "../services/quiz.service";
import ToastService from "../services/toast.service";
export default {
  name: "QuizView",
  components: { AppFooter, AppLoading },
  data() {
    return {
      loading: true,
      congratsDialog: false,
      submitButton: true,
      tryAgainButton: false,
      activeQuestionIndex: 0,
      answerId: false,
      prizeAmounts: [500, 1000, 5000],
      questions: false,
      correctAnswers: 0,
      incorrectAnswers: 0,
      percentage: 0,
    };
  },
  mounted() {
    QuizService.getQuiz(this.$route.params.quiz_id).then((response) => {
      this.questions = response.data.questions;
      this.loading = false;
    });
  },
  methods: {
    submitQuiz() {
      //let that = this;
      let questionArray = this.questions.filter(
        (question) => question.selected_answer > 0
      );
      if (this.questions.length != questionArray.length) {
        ToastService.toast().error("Please Answer All Question!");
      } else {
        this.submitButton = false;
        var data = {};
        this.questions.forEach((question) => {
          data[question.question_id] = question.selected_answer;
        });

        QuizService.submitQuiz(this.$route.params.quiz_id, data).then(
          (response) => {
            this.congratsDialog = true;
            let totalAnswers = this.questions.length;
            let wrongAnswers = 0;
            let rightAnswers = 0;

            if (Object.keys(response.data).includes("mistakes")) {
              wrongAnswers = response.data.mistakes.length;
            }

            rightAnswers = totalAnswers - wrongAnswers;
            let percentage = (rightAnswers / totalAnswers) * 100;
            let decimalCheck = percentage - Math.floor(percentage) !== 0;

            if (decimalCheck) {
              this.percentage = percentage.toFixed(2);
            } else {
              this.percentage = percentage;
            }
            this.correctAnswers = rightAnswers;
            this.incorrectAnswers = wrongAnswers;

            if (response.data.success) {
              this.tryAgainButton = false;
              // ToastService.toast().success("Selected Successfully.");
              // setTimeout(() => {
              //   that.congratsDialog = false;
              //   that.$router.back();
              // }, 10000);
            } else {
              this.tryAgainButton = true;
              // let mistakes = response.data.mistakes;
              // let wrongAnswers = [];
              // mistakes.forEach((mistake) => {
              //   wrongAnswers.push(mistake.invalid_answer);
              // });
              // this.questions.forEach((question) => {
              //   question.answers.forEach((answer) => {
              //     if (question.selected_answer == answer.answer_id) {
              //       answer.answer_class = "success";
              //     }
              //   });
              // });
              // this.questions.forEach((question) => {
              //   question.answers.forEach((answer) => {
              //     if (wrongAnswers.includes(answer.answer_id)) {
              //       answer.answer_class = "danger";
              //     }
              //   });
              // });
              //ToastService.toast().error("Failed.");
            }
          }
        );
      }
    },
    tryAgain() {
      this.$router.go();
    },
    checkAnswer() {
      if (this.answerId && this.answerId > 0) {
        this.questions[this.activeQuestionIndex].selected_answer =
          this.answerId;
        this.answerId = false;
        if (this.activeQuestionIndex + 1 == this.questions.length) {
          this.submitQuiz();
        } else {
          this.activeQuestionIndex++;
        }
      } else {
        ToastService.toast().error("Please select answer!");
      }
    },
  },
};
</script>
