<template>
  <div class="course_page bg_7 h_100 position-relative">
    <AppHeader></AppHeader>
    <div class="container">
      <div class="">
        <div
          class="col-sm-12 col-lg-12 col-xl-10 col-xxl-8 col-12 col-md-12 mx-auto burger"
        >
          <div class="text-center mb-5">
            <h1 class="heading_1">HAVE FUN PRACTICING & PLAYING</h1>
          </div>
          <div class="row justify-content-center">
            <div
              v-for="practice in practices"
              :key="practice.id"
              class="col-md-4 col-sm-4"
              :class="practice.locked ? 'lock-box' : ''"
            >
              <div class="practice_list">
                <div class="practice">
                  <div class="sky">
                    <img src="@/assets/sky.png" alt="sky" />
                  </div>
                  <h3 class="heading_sm_2 mb-0">{{ practice.name }}</h3>
                  <ul>
                    <li
                      v-for="exerciseNo in practice.exercise_types"
                      :key="exerciseNo"
                    >
                      <router-link
                        :to="{
                          name: 'practice-exercise-' + exerciseNo,
                          params: {
                            lesson_id: this.$route.params.lesson_id,
                            practice_id: practice.id,
                          },
                        }"
                        v-if="
                          (practice.completed_exercises.includes(exerciseNo) ||
                            practice.current_exercise == exerciseNo) &&
                          !practice.locked
                        "
                        >Exercise {{ exerciseNo }}</router-link
                      >
                      <span v-else class="lock-box">
                        <i class="fas fa-lock me-1"></i> Exercise
                        {{ exerciseNo }}</span
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
import PractiseService from "../services/practise.service";
export default {
  name: "PracticingsView",
  components: { AppFooter, AppHeader },
  data() {
    return {
      loading: true,
      practices: false,
    };
  },
  mounted() {
    PractiseService.getPractise(this.$route.params.lesson_id).then(
      (response) => {
        this.practices = response.data.practices;
        this.loading = false;
      }
    );
  },
};
</script>
