import { createApp } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import i18n from "./i18n";

import Dialog from "primevue/dialog";
import PrimeVue from "primevue/config";
import SplitButton from "primevue/splitbutton";
import Sidebar from "primevue/sidebar";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./css/style.css";
import "./css/fonts/fonts.css";
import { authInterceptor } from "./interceptors/auth.interceptor";
import { contentTypeInterceptor } from "./interceptors/contentType.interceptor";
import { responseInterceptor } from "./interceptors/response.interceptor";

const app = createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueAxios, axios)
  .use(Toast)
  .use(PrimeVue);
app.component("DialogPopup", Dialog);
app.component("SplitButton", SplitButton);
app.component("SideBar", Sidebar);
authInterceptor();
contentTypeInterceptor();
responseInterceptor();
app.config.globalProperties.axios = axios;
app.mount("#app");
// router.isReady().then(() => {
//   app.mount("#app");
// });
