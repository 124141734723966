<template>
  <div class="loader">
    <img src="@/assets/loading-gif.gif" />
  </div>
</template>
<script>
export default {
  name: "AppLoading",
};
</script>
