<template>
  <div class="exercise bg_2 h_100 position-relative">
    <AppHeader></AppHeader>
    <!-- <div class="childer-imgs">
      <div class="chil_img1">
        <img src="@/assets/pencil_child.png" />
      </div>
      <div class="chil_img2">
        <img src="@/assets/laptop_girl.png" />
      </div>
      <div class="chil_img3">
        <img src="@/assets/half_book.png" />
      </div>
      <div class="chil_img4">
        <img src="@/assets/book_readign.png" />
      </div>
    </div> -->
    <div class="container">
      <div class="pb-5">
        <div class="heading-lg justify-content-center d-flex text-center">
          <h1>EXERCISE 7</h1>
        </div>
        <div class="girl_section mx-auto">
          <div class="row">
            <div class="col-sm-6" v-if="!isExerciseCompleted">
              <div class="qut-img">
                <img class="w-100" src="@/assets/girl_1.png" />
              </div>
            </div>
            <div
              class="col-sm-6 d-flex justify-content-center text-center align-items-center exercise7"
              v-if="!isExerciseCompleted"
            >
              <div class="py-3 pe-4 ps-4 ps-sm-0 ps-lg-0">
                <h4 class="">{{ question.text }}</h4>
                <div class="">
                  <div
                    class="bottom-hand"
                    v-bind:class="showTextAfterResponse == true ? 'd-none' : ''"
                  >
                    <div class="hands"></div>
                  </div>
                  <div class="">
                    <img
                      class="fa-smile my-2"
                      width="70"
                      src="@/assets/smile.svg"
                      v-if="isAnswerRight"
                    />
                    <img
                      class="fa-angry my-2"
                      width="70"
                      src="@/assets/devil.svg"
                      v-if="isAnswerWrong"
                    />
                  </div>
                  <div class="ans1">
                    <div class="translate-section">
                      <input
                        class="border-0 rounded-0 form-control"
                        placeholder="Write Here"
                        type="text"
                        @keyup="showSubmitButton($event)"
                        :value="answers"
                        :disabled="inputDisabled == true"
                      />
                      <img class="pencil-dt" src="@/assets/pencil-text.svg" />
                      <span class="small mt-4 fw-bold">English</span>
                    </div>
                  </div>
                  <div class="text-center" v-if="submitButton">
                    <span class="leftanimat">
                      <img class="" width="39" src="@/assets/lefthand.svg" />
                    </span>
                    <button
                      class="bg-transparent border-0 mt-3 next-btn"
                      @click="checkAnswer()"
                      v-if="submitButton"
                      v-bind:class="addBikeSpeedActive == false ? '' : 'active'"
                    >
                      <!-- <span>Submit</span> -->
                      <img width="73" src="@/assets/motarbike.svg" />
                    </button>
                  </div>

                  <div class="text-center" v-if="nextButton">
                    <span class="leftanimat">
                      <img class="" width="39" src="@/assets/lefthand.svg" />
                    </span>
                    <button
                      class="bg-transparent border-0 mt-3 next-btn"
                      @click="nextQuestionClick()"
                      v-if="nextButton"
                      v-bind:class="addBikeSpeedActive == false ? '' : 'active'"
                    >
                      <!-- <span>Next</span> -->
                      <img width="73" src="@/assets/motarbike.svg" />
                    </button>
                  </div>
                  <div>
                    <div
                      class="next-text"
                      v-if="
                        showTextAfterResponse == true && isAnswerRight == true
                      "
                    >
                      Next
                    </div>
                    <div
                      class="angry-text"
                      v-if="
                        showTextAfterResponse == true && isAnswerWrong == true
                      "
                    >
                      Try Again
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12" v-if="isExerciseCompleted">
              <button
                class="btn texture_btn btn-success btn-sm circle_texture text-black mt-4"
                @click="$router.go($router.currentRoute)"
              >
                <span> Try Again </span>
              </button>
              <div
                class="btn texture_btn btn-success btn-sm circle_texture text-black mt-4"
              >
                <router-link
                  v-if="!practice_complete"
                  :to="{
                    name: 'practice-exercise-' + nextExercise,
                    params: {
                      lesson_id: this.$route.params.lesson_id,
                      practice_id: this.$route.params.practice_id,
                    },
                  }"
                  >Next Exercise</router-link
                >
                <router-link
                  v-else-if="practice_complete"
                  :to="{
                    name: 'practicings',
                    params: {
                      lesson_id: this.$route.params.lesson_id,
                    },
                  }"
                  ><span> Next Exercise </span></router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppExcersiceFooter.vue";
// import ToastService from "@/services/toast.service";
import { BgColors } from "@/constants/BgColors";
import PractiseService from "../../services/practise.service";
import { getNextPractice } from "../../helpers/GetNextPractice";

export default {
  name: "Exercise7View",
  components: { AppFooter, AppHeader },
  data() {
    return {
      loading: false,
      word: false,
      bgColors: BgColors,

      questionIndex: 0,
      question: {},
      answers: [],
      DEBUG_ANSWER: "",
      checkAnswerForSubmit: [],
      practice_complete: false,
      exercise_complete: false,
      correct_answer: false,
      isAnswerRight: false,
      isAnswerWrong: false,
      answerId: "",
      isAnswerDisabled: false,
      isExerciseCompleted: false,
      nextButton: false,
      submitButton: false,
      nextExercise: "",
      listen: true,
      inputDisabled: false,
      addBikeSpeedActive: false,
      showTextAfterResponse: false,
    };
  },
  mounted() {
    this.loadQuestion();
  },
  methods: {
    async loadQuestion() {
      this.question = {};
      this.answers = [];
      this.checkAnswerForSubmit = [];
      const data = {};
      this.inputDisabled = false;
      this.showTextAfterResponse = false;
      data["exercise"] = 7;
      let response = await PractiseService.getExerciseContent(
        this.$route.params.practice_id,
        data
      );
      this.question = await response.data.question;
      this.DEBUG_ANSWER = await response.data.question.DEBUG_ANSWER;
      console.log(this.question);
      this.loading = false;
      this.isAnswerRight = false;
      this.isAnswerWrong = false;
      this.isAnswerDisabled = false;
      this.nextButton = false;
      this.submitButton = false;
      this.nextExercise = await getNextPractice(
        this.$route.params.lesson_id,
        this.$route.params.practice_id,
        7
      );
      this.addBikeSpeedActive = false;
    },
    showSubmitButton(e) {
      this.submitButton = true;
      this.answers = e.target.value;
    },
    checkAnswer() {
      this.addBikeSpeedActive = true;
      const data = {};
      data["exercise"] = 7;
      data["answer"] = this.answers;
      PractiseService.submitExerciseAnswer(
        this.$route.params.practice_id,
        data
      ).then((response) => {
        console.log(response);
        this.practice_complete = response.data.practice_complete;
        this.exercise_complete = response.data.exercise_complete;
        this.correct_answer = response.data.correct_answer;
        this.submitButton = false;
        this.nextButton = true;
        this.addBikeSpeedActive = false;
        if (this.nextButton == true) {
          this.inputDisabled = true;
        }
        // this.isAnswerDisabled = true;
        if (this.correct_answer && !this.exercise_complete) {
          this.isAnswerRight = true;
          this.showTextAfterResponse = true;
        }
        if (this.correct_answer && this.exercise_complete) {
          this.isAnswerRight = true;
          this.showTextAfterResponse = true;
        }

        if (!this.correct_answer) {
          this.isAnswerWrong = true;
          this.showTextAfterResponse = true;
        }
      });
    },
    runNextQuestion() {
      this.loadQuestion();
    },
    nextQuestionClick() {
      this.addBikeSpeedActive = true;
      this.isAnswerRight = false;
      this.isAnswerWrong = false;
      this.nextButton = true;
      this.showTextAfterResponse = false;
      if (this.exercise_complete) {
        this.nextButton = false;
        this.isExerciseCompleted = true;
      } else {
        this.runNextQuestion();
      }
      if (this.practice_complete) {
        this.nextButton = false;
        this.$router.push({
          name: "practicings",
          params: { lesson_id: this.$route.params.lesson_id },
        });
      }
    },
    generateRandomColor() {
      let randomIndex = Math.floor(Math.random() * this.bgColors.length);
      return this.bgColors[randomIndex];
    },
  },
};
</script>
