<template>
  <div
    class="login bg_9 h_100 position-relative justify-content-center align-items-center d-flex"
  >
    <div class="container">
      <div class="col-sm-11 col-lg-5 col-md-7 col-11 m-auto">
        <div class="card position-relative">
          <div class="sky_top"><img src="@/assets/sky_white.png" /></div>
          <div class="sky_bottom"><img src="@/assets/sky_white.png" /></div>
          <div class="logo text-center"><img src="@/assets/logo2.png" /></div>
          <h5 class="text-center">Reset Password</h5>
          <p class="text-muted text-center w-75 m-auto mb-5">
            Enter your email address below to receive a Password Reset link
          </p>
          <Form @submit="resetPasswordLink" :validation-schema="schema">
            <div class="mb-5">
              <label for="exampleInputEmail1" class="form-label"
                >Email address</label
              >
              <Field name="email" type="email" class="form-control" />
              <ErrorMessage name="email" class="error-feedback text-danger" />
            </div>
            <button class="btn btn-danger w-100 mt-5">
              <!-- <span v-show="loading" class="spinner-border spinner-border-sm"></span> -->
              <span>Submit</span>
            </button>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import AppFooter from "@/components/AppFooter.vue";
import UserService from "../services/user.service";
import ToastService from "../services/toast.service";
export default {
  name: "ResetPasswordLinkView",
  components: {
    AppFooter,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      email: yup
        .string()
        .email("Must be a valid email!")
        .required("Please enter email!"),
    });
    return {
      loading: false,
      schema,
    };
  },
  methods: {
    resetPasswordLink(data, { resetForm }) {
      UserService.resetPasswordLink(data).then((response) => {
        console.log(response);
        resetForm();
        ToastService.toast().success("Password Reset Link Sent Successfully.");
      });
    },
  },
};
</script>
