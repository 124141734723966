<template>
  <div class="storypage bg_2 position-relative">
    <AppHeader></AppHeader>
    <div class="container">
      <div class="">
        <div class="heading-lg text-center">
          <h1 class="heading_2">EXERCISE 3</h1>
        </div>
        <div class="girl_section mx-auto">
          <div class="bg-white p-4 storyexercise3">
            <div class="row">
              <p class="heading_sm_2 mt-2 col-12 text-dark mb-3">
                Type the missing letters to complete the story
              </p>
              <div class="text-center my-3">
                <img v-if="isAnswerWrong" width="75" src="@/assets/devil.svg" />
                <img
                  v-if="isAnswerRight"
                  class="me-2"
                  width="75"
                  src="@/assets/smile.svg"
                />
              </div>
              <div class="sentences words-list">
                <div class="row">
                  <h4 class="col-6 col-lg-8 mb-4">Sentences</h4>

                  <h4 class="mb-4 text-center col-6 col-lg-4">Words</h4>
                </div>
                <div
                  class="row d-none1"
                  v-for="(queSentence, index) in answers"
                  :key="queSentence.index"
                >
                  <h6
                    class="col-6 col-lg-8 mb-3 align-items-start d-flex"
                    v-html="queSentence.sentence"
                    @drop="
                      drop($event, queSentence.index, queSentence.sentence)
                    "
                    @dragover="allowDrop($event)"
                    v-bind:id="'drop' + index"
                  ></h6>
                  <h6
                    class="mb-3 col-6 col-lg-4"
                    v-bind:id="'dropIndexId_' + index"
                  >
                    <span
                      v-bind:id="'drag' + index"
                      :draggable="inputDisabled == false"
                      @dragstart="drag($event, wordList[queSentence.index])"
                    >
                      {{ wordList[queSentence.index] }}
                    </span>
                  </h6>
                </div>
                <!-- <div class="row">
                    <h6 class="mb-3 col-sm-8 col-sm-7">
                      2) Laura is a<input class="emptyspace" type="text" /> .
                    </h6>
                    <h6 class="mb-3 col-sm-4 col-5 d-none"><span> has </span></h6>
                    <h6 class="mb-3 col-sm-4 col-5 text-center">
                      <img class="me-2" width="32" src="@/assets/smile.svg" />
                    </h6>
                  </div>
                  <div class="row">
                    <h6 class="mb-3 col-sm-8 col-sm-7">
                      3) Carly has<input class="emptyspace" type="text" /> hair.
                    </h6>
                    <h6 class="mb-3 col-sm-4 col-5 d-none">
                      <span> good </span>
                    </h6>
                    <h6 class="mb-3 col-sm-4 col-5 text-center">
                      <img class="me-2" width="32" src="@/assets/devil.svg" />
                    </h6>
                  </div>
                  <div class="row">
                    <h6 class="mb-3 col-sm-8 col-sm-7">
                      4) jesse<input class="emptyspace" type="text" /> a cat.
                    </h6>
                    <h6 class="mb-3 col-sm-4 col-5 d-none">
                      <span> Woman </span>
                    </h6>
                    <h6 class="mb-3 col-sm-4 col-5 text-center">
                      <img class="me-2" width="32" src="@/assets/smile.svg" />
                    </h6>
                  </div>
                  <div class="row">
                    <h6 class="mb-3 col-sm-8 col-sm-7">
                      5) Zombi<input class="emptyspace" type="text" /> a dog.
                    </h6>
                    <h6 class="mb-3 col-sm-4 col-5 d-none">
                      <span> Blonde </span>
                    </h6>
                    <h6 class="mb-3 col-sm-4 col-5 text-center">
                      <img class="me-2" width="32" src="@/assets/smile.svg" />
                    </h6>
                  </div> -->
              </div>
            </div>
          </div>
          <div class="my-2 text-center">
            <button
              v-if="isAnswerWrong"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              @click="$router.go($router.currentRoute)"
            >
              <span class="shad"></span><span>Try Again</span>
            </button>
            <button
              v-if="!isAnswerRight && !isExerciseCompleted && !isAnswerWrong"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              @click="submitAnswer"
            >
              <span class="shad"></span><span>Check</span>
            </button>

            <button
              v-else-if="isAnswerRight && !isExerciseCompleted"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              @click="submitAnswer"
            >
              <span class="shad"></span><span>Next</span>
            </button>

            <router-link
              v-else-if="isAnswerRight && isExerciseCompleted"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              :to="{
                name: 'story-exercise-' + nextExercise,
                params: {
                  lesson_id: this.$route.params.lesson_id,
                  storytime_id: this.$route.params.storytime_id,
                },
              }"
              ><span class="shad"></span><span>Next Exercise</span></router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppExcersiceFooter.vue";
import StoryTimeService from "../../services/story-time.service.js";
import { getNextStoryTime } from "../../helpers/GetNextStoryTime.js";
export default {
  name: "StoryExercise3View",
  components: { AppFooter, AppHeader },
  data() {
    return {
      loading: false,
      lessons: null,
      practice_complete: false,
      exercise_complete: false,
      correct_answer: "",
      isAnswerWrong: false,
      isAnswerRight: false,
      isAnswerDisabled: false,
      isExerciseCompleted: false,
      nextExercise: "",
      sentencesList: "",
      answerForSubmit: [],
      checkAnswerForSubmit: [],
      sentencesListForBK: "",
      word: "",
      answers: [],
      dragIndex: "",
      dropIndex: "",
      wordList: "",
      inputDisabled: false,
      contents: {
        sentences: [
          {
            sentence: "Hello _",
            missing_word: 1,
            index: 0,
          },
          {
            sentence: "How are _ doing today?",
            missing_word: 2,
            index: 1,
          },
          {
            sentence: "Have a nice _",
            missing_word: 3,
            index: 2,
          },
          {
            sentence: "I want _ eat an apple.",
            missing_word: 2,
            index: 3,
          },
        ],
        words: ["day!", "to", "there!", "you"],
      },
    };
  },
  created() {
    this.loadQuestion();
  },
  methods: {
    async loadQuestion() {
      const data = {};
      this.answerForSubmit = [];
      this.wordList = "";
      this.sentencesListForBK = "";
      this.word = "";
      this.answers = [];
      this.inputDisabled = false;
      this.dragIndex = "";
      this.dropIndex = "";

      this.checkAnswerForSubmit = [];
      data["exercise_id"] = 3;
      let response = await StoryTimeService.getExerciseContent(
        this.$route.params.storytime_id,
        data
      );
      this.sentencesList = response.data.content.sentences;
      this.sentencesListForBK = response.data.content.sentences;
      this.wordList = response.data.content.words;
      this.sentencesList.forEach((element, key) => {
        //   let htm = " ";
        //   htm +=
        //     '<input type="text" style="width: 70px" v-bind:id="wordEnter_' +
        //     key +
        //     '" v-on:click="setWord(' +
        //     key +
        //     ')" class="emptyspace"/>';
        //   let str = element.sentence.split("_").join(htm);
        //   element.sentence = element.index + 1 + ") " + str;

        let htm = " ";
        htm +=
          '<input type="text" dataDragIndex="' +
          key +
          '" style="width: 70px" class="emptyspace"  value="" id="emptyspace_' +
          key +
          '" readonly />';
        let str = element.sentence.split("_").join(htm);
        element.sentence = element.index + 1 + ") " + str;
      });

      this.answers = response.data.content.sentences;
      this.answers.forEach((element) => {
        // let htm = " ";
        // let str = element.sentence.split("_").join(htm);
        element.sentence = element.index + 1 + ") " + element.sentence;
      });
      // console.log(this.answers);
      this.loading = false;
      this.isAnswerRight = false;
      this.isAnswerDisabled = false;
      this.nextExercise = await getNextStoryTime(
        this.$route.params.lesson_id,
        this.$route.params.storytime_id,
        1
      );
      this.nextExercise = data["exercise_id"] + 1;
    },

    allowDrop(ev) {
      ev.preventDefault();
    },

    drag(ev, word) {
      this.word = word;
      this.dragIndex = this.getValueFromId(ev.target.id);
      ev.dataTransfer.setData("text", ev.target.id);
    },

    getValueFromId(txt) {
      let str = txt.replace(/[^0-9]/g, "");
      // console.log("str ", str);
      return str;
    },

    drop(ev, indexNo, question) {
      ev.preventDefault();
      var data = ev.dataTransfer.getData("text");
      ev.dataTransfer.setData("text", "");
      ev.target.appendChild(document.getElementById(data));
      if (this.word) {
        var myEle = document.getElementById("emptyspace_" + indexNo);
        if (myEle) {
          myEle.value = this.word;
          this.word = "";
          return;
        } else {
          let htm = " ";
          htm +=
            '<input type="text" dataDragIndex="' +
            this.dragIndex +
            '" style="width: 70px" class="emptyspace"  value="' +
            this.word +
            '" id="emptyspace_' +
            indexNo +
            '" readonly />';
          let str = question.split("_").join(htm);
          question = question.index + 1 + ") " + str;
          this.answers[indexNo].sentence = str;
          this.word = "";
          this.dragIndex = "";
        }
      } else {
        // console.log(2344);
        // var myEle1 = document.getElementById("emptyspace_" + indexNo);
        // // console.log(myEle1);
        // this.word = "";
        // if (myEle1) {
        //   myEle1.value = this.word;
        //   return;
        // } else {
        //   let htm = " ";
        //   htm +=
        //     '<input type="text" style="width: 70px" class="emptyspace"  value="' +
        //     this.word +
        //     '" id="emptyspace_' +
        //     indexNo +
        //     '" readonly />';
        //   let str = question.split("_").join(htm);
        //   question = question.index + 1 + ") " + str;
        //   this.answers[indexNo].sentence = str;
        // }
      }
    },

    selectWord(word) {
      this.word = word;
    },
    setWord(indexNo, question) {
      console.log(indexNo);
      console.log(question);
      console.log("word ", this.word);
      var myEle1 = document.getElementById("emptyspace_" + indexNo);
      if (myEle1) {
        var dragIndex = myEle1.getAttribute("dataDragIndex");
        var returnDropValue = document.getElementById(
          "dropIndexId_" + dragIndex
        );
        var htm1 = "<span>" + myEle1.value + "</span>";
        returnDropValue.innerHTML = htm1;
        myEle1.value = "";
        this.word = "";
        return;
      } else {
        let htm = " ";
        htm +=
          '<input type="text" dataDragIndex="' +
          this.dragIndex +
          '" style="width: 70px" class="emptyspace"  value="' +
          this.word +
          '" id="emptyspace_' +
          indexNo +
          '" readonly />';
        let str = question.split("_").join(htm);
        question = question.index + 1 + ") " + str;
        this.answers[indexNo].sentence = str;
        this.word = "";
        this.dragIndex = "";
      }
    },

    submitAnswer() {
      let str1 = this.sentencesList;
      let substr = "_";
      let index = str1.indexOf(substr);
      while (index !== -1) {
        index = str1.indexOf(substr, index + 1);
      }
      this.answerForSubmit = [];
      let valCount = 0;
      let totalCount = 0;
      document.querySelectorAll(".emptyspace").forEach((element) => {
        if (element.value == "") {
          valCount++;
        }
        this.answerForSubmit.push(element.value);
        totalCount++;
      });
      console.log(this.answerForSubmit);
      const data = {};
      data["exercise_id"] = 3;
      data["answer"] = Object.assign({}, this.answerForSubmit);
      StoryTimeService.submitExerciseAnswer(
        this.$route.params.storytime_id,
        data
      ).then((response) => {
        console.log(response);
        this.practice_complete = response.data.completed_storytime;
        this.exercise_complete = response.data.completed_exercise;
        this.correct_answer = response.data.valid_answer;
        if (this.correct_answer && !this.exercise_complete) {
          this.isAnswerRight = true;
          this.isAnswerWrong = false;
        }
        if (!this.correct_answer) {
          this.isAnswerRight = false;
          this.isAnswerWrong = true;
        }
        if (this.exercise_complete) {
          this.isExerciseCompleted = true;
          this.isAnswerWrong = false;
          this.isAnswerRight = true;
        }
      });
      console.log(valCount + " - " + totalCount);
    },
  },
};
</script>
