<template>
  <div class="login bg_9 h_100 position-relative">
    <div class="container">
      <div class="col-sm-11 col-lg-6 col-xl-5 col-md-8 col-11 m-auto">
        <div class="card position-relative">
          <div class="sky_top"><img src="../assets/sky_white.png" /></div>
          <div class="sky_bottom"><img src="../assets/sky_white.png" /></div>
          <div class="logo text-center">
            <img src="../assets/logo2.png" />
          </div>
          <h5 class="text-center">Login</h5>
          <div v-if="userPassError" class="text-danger">
            {{ userPassError }}
          </div>
          <Form @submit="handleLogin" :validation-schema="schema">
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label"
                >Username</label
              >

              <Field name="username" type="text" class="form-control" />
              <ErrorMessage
                name="username"
                class="error-feedback text-danger"
              />
            </div>
            <div class="mb-3">
              <label for="exampleInputPassword1" class="form-label"
                >Password</label
              >
              <Field name="password" type="password" class="form-control" />
              <ErrorMessage
                name="password"
                class="error-feedback text-danger"
              />
            </div>
            <div class="d-flex justify-content-between mb-5">
              <div class="mb-3 form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                />
                <label class="form-check-label" for="exampleCheck1"
                  >Keep me logged in</label
                >
              </div>
              <router-link to="/reset-password-link" class="text-danger"
                >Forgot Password?</router-link
              >
            </div>
            <button class="btn btn-danger w-100">
              <!-- <span v-show="loading" class="spinner-border spinner-border-sm"></span> -->
              <span>Login</span>
            </button>

            <div class="text-center mt-4">
              <router-link to="/contact" class="nav-link text-dark mb-2"
                >Not a Member?</router-link
              >
              <router-link to="/find-out-more" class="find_btn"
                >Find out more</router-link
              >
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import AppFooter from "@/components/AppFooter.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
export default {
  name: "LoginView",
  components: {
    AppFooter,
    Form,
    Field,
    ErrorMessage,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Username is required!"),
      password: yup.string().required("Password is required!"),
    });

    return {
      loading: false,
      userPassError: false,
      message: "",
      schema,
    };
  },
  methods: {
    handleLogin(user) {
      this.userPassError = false;
      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.push("/my-courses");
        },
        (error) => {
          this.loading = false;
          this.userPassError = "Invalid username or password!";
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>
