<template>
  <div
    class="login edit-profile bg_9 h_100 position-relative d-flex justify-content-center align-items-center"
  >
    <div class="container">
      <div class="col-sm-10 col-10 m-auto col-md-10 col-lg-6">
        <div class="card position-relative">
          <div class="sky_top"><img src="@/assets/sky_white.png" /></div>
          <div class="sky_bottom"><img src="@/assets/sky_white.png" /></div>
          <div class="logo text-center"><img src="@/assets/logo2.png" /></div>
          <h5 class="text-center">Edit profile</h5>
          <Form @submit="updateProfile" :validation-schema="schema">
            <div class="mb-3">
              <label for="first_name" class="form-label">First name</label>
              <Field name="first_name" type="text" class="form-control" />
              <ErrorMessage
                name="first_name"
                class="error-feedback text-danger"
              />
            </div>
            <div class="mb-3">
              <label for="last_name" class="form-label">Last name</label>
              <Field name="last_name" type="text" class="form-control" />
              <ErrorMessage
                name="last_name"
                class="error-feedback text-danger"
              />
            </div>
            <button class="btn btn-danger w-100 mt-5">
              <!-- <span v-show="loading" class="spinner-border spinner-border-sm"></span> -->
              <span>Send</span>
            </button>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import AppFooter from "@/components/AppFooter.vue";
import UserService from "../services/user.service";
import ToastService from "../services/toast.service";
export default {
  name: "ProfileEditView",
  components: {
    AppFooter,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      first_name: yup.string().required("Please enter first name!"),
      last_name: yup.string().required("Please enter last name!"),
    });
    return {
      loading: false,
      schema,
    };
  },
  methods: {
    updateProfile(data, { resetForm }) {
      UserService.updateProfile(data).then((response) => {
        console.log(response);
        this.message = "";
        resetForm();
        ToastService.toast().success("Profile Updated Successfully.");
        this.$router.push("/profile");
      });
    },
  },
};
</script>
