<template>
  <div class="exercise bg_2 h_100 position-relative">
    <AppHeader></AppHeader>
    <!-- <div class="childer-imgs">
      <div class="chil_img1">
        <img src="@/assets/pencil_child.png" />
      </div>
      <div class="chil_img2">
        <img src="@/assets/laptop_girl.png" />
      </div>
      <div class="chil_img3">
        <img src="@/assets/half_book.png" />
      </div>
      <div class="chil_img4">
        <img src="@/assets/book_readign.png" />
      </div>
    </div> -->
    <div class="container">
      <div class="pb-5">
        <div class="heading-lg justify-content-center d-flex text-center">
          <h1>EXERCISE 2</h1>
        </div>
        <div class="girl_section mx-auto">
          <div class="row">
            <div class="col-sm-6" v-if="!isExerciseCompleted">
              <div class="qut-img">
                <img class="w-100" src="@/assets/girl_1.png" />
              </div>
            </div>
            <div
              class="col-sm-6 d-flex justify-content-center text-center align-items-center exercise2"
              v-if="!isExerciseCompleted"
            >
              <div class="py-3 me-md-5 ms-md-4">
                <h4 class="mb-3">{{ question.text }}</h4>
                <div v-if="listen">
                  <div class="button2">
                    <div class="hands"></div>
                    <img
                      v-if="Object.keys(question).includes(sound)"
                      class="my-4"
                      @click.prevent="playSound(question.sound)"
                      width="93"
                      src="@/assets/volume.png"
                    />
                  </div>
                  <div
                    class="ans"
                    :class="
                      answer.id == answerId
                        ? isAnswerRight
                          ? 'right_ans'
                          : isAnswerWrong
                          ? 'wrong_ans'
                          : ''
                        : ''
                    "
                    v-for="answer in answers"
                    :key="answer.id"
                  >
                    <!-- <i class="far fa-smile" v-if="isAnswerRight"></i> -->
                    <!-- <i class="far fa-angry" v-if="isAnswerWrong"></i> -->
                    <img
                      class="fa-smile"
                      width="40"
                      src="@/assets/smile.svg"
                      v-if="isAnswerRight"
                    />
                    <img
                      class="fa-angry"
                      width="40"
                      src="@/assets/devil.svg"
                      v-if="isAnswerWrong"
                    />
                    <a
                      href="javascript:;"
                      @click="checkAnswer(answer.id)"
                      v-if="!isAnswerDisabled"
                    >
                      {{ answer.text }}
                    </a>
                    <a class="lock-box" v-else>{{ answer.text }}</a>
                  </div>

                  <div class="text-center" v-if="nextButton">
                    <span class="leftanimat">
                      <img class="" width="39" src="@/assets/lefthand.svg" />
                    </span>
                    <button
                      class="bg-transparent border-0 mt-3 next-btn"
                      @click="submitAndNextQue()"
                      v-bind:class="addBikeSpeedActive == false ? '' : 'active'"
                      v-if="nextButton"
                    >
                      <!-- <span>Next</span> -->
                      <img width="73" src="@/assets/motarbike.svg" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="practice-exercise-procces" v-if="isExerciseCompleted">
            <div
              class="col-sm-12 conten flex-column justify-content-center align-items-center d-flex"
            >
              <button
                class="btn texture_btn btn-success btn-sm cancel_texture w-50 mb-4"
                @click="$router.go($router.currentRoute)"
              >
                <span> Try Again </span>
              </button>
              <router-link
                class="btn texture_btn btn-info btn-sm heart_texture w-50 align-items-center justify-content-center d-flex"
                :to="{
                  name: 'practice-exercise-' + nextExercise,
                  params: {
                    lesson_id: this.$route.params.lesson_id,
                    practice_id: this.$route.params.practice_id,
                  },
                }"
              >
                <span> Next Exercise </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppExcersiceFooter.vue";
import PractiseService from "../../services/practise.service";
import { getNextPractice } from "../../helpers/GetNextPractice";
//import ToastService from "@/services/toast.service";
export default {
  name: "Exercise2View",
  components: { AppFooter, AppHeader },
  data() {
    return {
      loading: false,
      listen: true,
      questionIndex: 0,
      question: {},
      answers: [],
      practice_complete: false,
      exercise_complete: false,
      correct_answer: false,
      isAnswerRight: false,
      isAnswerWrong: false,
      answerId: "",
      isAnswerDisabled: false,
      isExerciseCompleted: false,
      nextButton: false,
      nextExercise: "",
      addBikeSpeedActive: false,
    };
  },
  mounted() {
    this.loadQuestion();
  },
  methods: {
    async loadQuestion() {
      const data = {};
      data["exercise"] = 2;
      let response = await PractiseService.getExerciseContent(
        this.$route.params.practice_id,
        data
      );
      this.question = await response.data.question;
      this.answers = await response.data.answers;
      this.questionIndex = await response.data.current_index;
      this.loading = false;
      this.isAnswerRight = false;
      this.isAnswerWrong = false;
      this.isAnswerDisabled = false;
      this.nextButton = false;
      this.addBikeSpeedActive = false;
      this.nextExercise = await getNextPractice(
        this.$route.params.lesson_id,
        this.$route.params.practice_id,
        2
      );
      console.log(this.nextExercise);
      // this.nextExercise = data["exercise"] + 1;
    },
    playSound(sound) {
      let that = this;
      if (sound) {
        var audio = new Audio(sound);
        audio.play();
        that.listen = false;
        // setTimeout(function () {
        //   that.listen = false;
        // }, that.questions[that.questionIndex].audio_duration * 1000);
      }
    },
    submitAndNextQue() {
      this.addBikeSpeedActive = true;
      this.loadQuestion();
    },
    playAudio() {
      this.listen = false;
    },
    checkAnswer(answerId) {
      const data = {};
      data["exercise"] = 2;
      data["answer_id"] = answerId;
      PractiseService.submitExerciseAnswer(
        this.$route.params.practice_id,
        data
      ).then((response) => {
        this.practice_complete = response.data.practice_complete;
        this.exercise_complete = response.data.exercise_complete;
        this.correct_answer = response.data.correct_answer;
        this.answerId = answerId;
        this.isAnswerDisabled = true;
        if (this.correct_answer && !this.exercise_complete) {
          this.isAnswerRight = true;
        }

        if (!this.correct_answer) {
          this.isAnswerWrong = true;
        }

        this.nextButton = true;

        if (this.exercise_complete) {
          this.nextButton = false;
          this.isExerciseCompleted = true;
        }

        if (this.practice_complete) {
          this.nextButton = false;
          this.$router.push({
            name: "practicings",
            params: { lesson_id: this.$route.params.lesson_id },
          });
        }
      });

      // if (answer == this.questions[this.questionIndex].target_sentence) {
      //   ToastService.toast().success("Successfully.");
      //   this.questionIndex++;
      //   this.listen = true;
      // } else {
      //   ToastService.toast().error("Try again!");
      // }
    },
  },
};
</script>
