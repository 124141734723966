<template>
  <div class="login bg_9 h_100 position-relative resetpassword">
    <div class="container">
      <div class="col-sm-11 col-lg-5 col-md-7 col-11 m-auto">
        <div class="card position-relative">
          <div class="sky_top"><img src="@/assets/sky_white.png" /></div>
          <div class="sky_bottom"><img src="@/assets/sky_white.png" /></div>
          <div class="logo text-center"><img src="@/assets/logo2.png" /></div>
          <h5 class="text-center">Reset Password</h5>
          <!-- <p class="text-muted text-center w-75 m-auto mb-5">
            Enter your email address below to receive a Password Reset link
          </p> -->
          <Form @submit="resetPassword" :validation-schema="schema">
            <div class="mb-5">
              <label for="exampleInputEmail1" class="form-label"
                >New password</label
              >
              <Field name="new_password" type="text" class="form-control" />
              <ErrorMessage
                name="new_password"
                class="error-feedback text-danger"
              />
            </div>
            <Field
              name="token"
              type="hidden"
              :value="this.$route.query.token"
            />
            <button class="btn btn-danger w-100 mt-5">
              <!-- <span v-show="loading" class="spinner-border spinner-border-sm"></span> -->
              <span>Reset Password</span>
            </button>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import AppFooter from "@/components/AppFooter.vue";
import UserService from "../services/user.service";
import ToastService from "../services/toast.service";
export default {
  name: "ResetPasswordView",
  components: {
    AppFooter,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      new_password: yup.string().required("Please enter new password!"),
      token: yup.string().required("Please enter token!"),
    });
    return {
      loading: false,
      schema,
    };
  },
  mounted() {
    console.log(this.$route.query.token);
  },
  methods: {
    resetPassword(data, { resetForm }) {
      UserService.resetPassword(data).then((response) => {
        console.log(response);
        resetForm();
        ToastService.toast().success("Password Reset Successfully.");
      });
    },
  },
};
</script>
