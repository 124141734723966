import axios from "axios";

class UnitService {
  getUnits(courseId) {
    return axios.get(process.env.VUE_APP_API + "/get_units/" + courseId);
  }
  // To Lock/Unlock Unit
  getUnlockNextUnit(data) {
    return axios.post(
      process.env.VUE_APP_API + "/debug_set_completion/",
      JSON.stringify(data)
    );
  }
}

export default new UnitService();
