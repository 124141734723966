<template>
  <div class="login bg_9 h_100 position-relative">
    <div class="container">
      <div class="col-sm-11 col-lg-6 col-xl-10 col-md-8 col-11 m-auto">
        <div class="card position-relative">
          <div class="sky_top"><img src="../assets/sky_white.png" /></div>
          <div class="sky_bottom"><img src="../assets/sky_white.png" /></div>
          <div class="logo text-center"><img src="../assets/logo2.png" /></div>
          <h5 class="text-center">Privacy Policy</h5>
          <div class="privacypolicy col-10 mx-auto">
            <h6><span> INTRODUCTION </span></h6>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>

            <h6><span> CHILDREN'S PRIVACY </span></h6>

            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import * as yup from "yup";
import AppFooter from "@/components/AppFooter.vue";
import UserService from "../services/user.service";
import ToastService from "../services/toast.service";
export default {
  name: "PrivacyView",
  components: {
    AppFooter,
  },
  data() {
    const schema = yup.object().shape({
      email: yup
        .string()
        .email("Must be a valid email!")
        .required("Please enter email!"),
      title: yup.string().required("Please enter title!"),
    });
    return {
      message: "",
      loading: false,
      schema,
    };
  },
  methods: {
    sendContactMessage(data, { resetForm }) {
      console.log(data);
      data.content = this.message;
      data.name = "Bolubas Marcell";
      UserService.sendContactMessage(data).then((response) => {
        console.log(response);
        this.message = "";
        resetForm();
        ToastService.toast().success("Message Sent Successfully.");
      });
    },
  },
};
</script>
