import axios from "axios";

export function authInterceptor() {
  axios.interceptors.request.use((request) => {
    let user = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(localStorage.getItem("user"));
      request.headers.common.Authorization =
        user.usernameBase64 + ":" + user.passwordBase64;
    } else {
      request.headers.common.Authorization = ``;
    }
    return request;
  });
}
