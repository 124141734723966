<template>
  <div class="login bg_9 h_100 position-relative">
    <div class="container">
      <div class="col-sm-11 col-lg-6 col-xl-5 col-md-8 col-11 m-auto">
        <div class="card position-relative">
          <div class="sky_top"><img src="../assets/sky_white.png" /></div>
          <div class="sky_bottom"><img src="../assets/sky_white.png" /></div>
          <div class="logo text-center"><img src="../assets/logo2.png" /></div>
          <h5 class="text-center">Contact Us</h5>
          <Form @submit="sendContactMessage" :validation-schema="schema">
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label"
                >Your Email</label
              >
              <Field name="email" type="email" class="form-control" />
              <ErrorMessage name="email" class="error-feedback text-danger" />
            </div>
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">Title</label>
              <Field name="title" type="text" class="form-control" />
              <ErrorMessage name="title" class="error-feedback text-danger" />
            </div>
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label"
                >Your Message</label
              >
              <textarea
                name="content"
                v-model="message"
                class="form-control"
                rows="4"
                required
              >
              </textarea>
            </div>
            <button class="btn btn-danger w-100 mt-5">
              <!-- <span v-show="loading" class="spinner-border spinner-border-sm"></span> -->
              <span>Send</span>
            </button>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import AppFooter from "@/components/AppFooter.vue";
import UserService from "../services/user.service";
import ToastService from "../services/toast.service";
export default {
  name: "ContactView",
  components: {
    AppFooter,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      email: yup
        .string()
        .email("Must be a valid email!")
        .required("Please enter email!"),
      title: yup.string().required("Please enter title!"),
    });
    return {
      message: "",
      loading: false,
      schema,
    };
  },
  methods: {
    sendContactMessage(data, { resetForm }) {
      console.log(data);
      data.content = this.message;
      data.name = "Bolubas Marcell";
      UserService.sendContactMessage(data).then((response) => {
        console.log(response);
        this.message = "";
        resetForm();
        ToastService.toast().success("Message Sent Successfully.");
      });
    },
  },
};
</script>
