<template>
  <div
    class="member_area bg_9 h_100 position-relative justify-conten-center d-flex align-items-center"
  >
    <div class="container">
      <div class="col-sm-10 col-10 m-auto col-md-10 col-lg-5">
        <div class="card position-relative">
          <div class="sky_top"><img src="@/assets/sky_white.png" /></div>
          <div class="sky_bottom"><img src="@/assets/sky_white.png" /></div>
          <div class="logo text-center"><img src="@/assets/logo2.png" /></div>
          <div class="profile text-center">
            <img
              v-if="this.$store.state.auth.profile.profile_picture != '#'"
              :src="this.$store.state.auth.profile.profile_picture"
            />
            <img v-else src="@/assets/profile_img.png" />
          </div>
          <h5 class="text-center">
            {{ this.$store.state.auth.profile.first_name }}
            {{ this.$store.state.auth.profile.last_name }}
          </h5>
          <ul class="nav flex-column">
            <li class="nav-item">
              <a
                class="nav-link active d-flex justify-content-between align-items-center"
                aria-current="page"
                href="/profile-edit"
              >
                <span class=""
                  ><img
                    class="me-2"
                    width="19"
                    src="@/assets/profile_clip.png"
                  />
                  My Profile
                </span>
                <img width="11" src="@/assets/arrow.png"
              /></a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link d-flex justify-content-between align-items-center"
                href="/language"
              >
                <span class="">
                  <img
                    class="me-2"
                    width="24"
                    src="@/assets/language_clip.png"
                  />
                  Language Selection
                </span>
                <img width="11" src="@/assets/arrow.png"
              /></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <AppFooter></AppFooter>
</template>

<script>
//import { mapGetters } from "vuex";
import AppFooter from "@/components/AppFooter.vue";
export default {
  name: "ProfileView",
  components: { AppFooter },
  mounted() {
    console.log("aaaaaaaaaaaa");
  },
};
</script>
