<template>
  <div class="storypage bg_2 position-relative" v-if="!isExerciseCompleted">
    <AppHeader></AppHeader>
    <div class="container">
      <div class="">
        <div class="heading-lg text-center">
          <h1 class="heading_2">EXERCISE 8</h1>
        </div>

        <div class="girl_section mx-auto">
          <div class="bg-white p-4 storyexercise7">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="mb-1 text-center">Translation</h4>
                <p class="heading_sm_2 mb-5 mt-2 text-dark">
                  Write the sentences is {{ Language }}
                </p>

                <div class="text-center mb-3">
                  <img
                    v-if="isAnswerWrong"
                    width="75"
                    src="@/assets/devil.svg"
                  />
                  <img
                    v-if="isAnswerRight"
                    class="me-2"
                    width="75"
                    src="@/assets/smile.svg"
                  />
                </div>
                <form>
                  <div
                    class="qn-ans mb-4"
                    v-for="(question, index) in questionList"
                    :key="index"
                  >
                    <h6 class="mb-3">
                      {{ index + 1 }}) {{ question.sentence }}
                    </h6>
                    <div class="d-flex">
                      <span class="fw-bold"> {{ index + 1 }}) </span>
                      <span class="mx-1">:</span>
                      <div class="ans w-100">
                        <input
                          placeholder="Write Here"
                          class="answerData"
                          v-bind:class="'answer_' + index"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- <div class="qn-ans mb-4">
                    <h6 class="mb-3">1) John is a Writer. /question/</h6>
                    <div class="d-flex">
                      <span class="fw-bold"> A </span>
                      <span class="mx-1">:</span>
                      <div class="ans w-100">
                        <input placeholder="Write Here" class="" type="text" />
                      </div>
                    </div>
                  </div>
                  <div class="qn-ans mb-4">
                    <h6 class="mb-3">2) Laura is a mother. /question/</h6>
                    <div class="d-flex">
                      <span class="fw-bold"> A </span>
                      <span class="mx-1">:</span>
                      <div class="ans w-100">
                        <input placeholder="Write Here" class="" type="text" />
                      </div>
                    </div>
                  </div>
                  <div class="qn-ans mb-4">
                    <h6 class="mb-3">3) Carly ha blonde hair. /negative/</h6>
                    <div class="d-flex">
                      <span class="fw-bold"> A </span>
                      <span class="mx-1">:</span>
                      <div class="ans w-100">
                        <input placeholder="Write Here" class="" type="text" />
                      </div>
                    </div>
                  </div> -->
                </form>
              </div>
            </div>
          </div>
          <div class="my-2 text-center">
            <button
              v-if="isAnswerWrong"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              @click="$router.go($router.currentRoute)"
            >
              <span class="shad"></span><span>Try Again</span>
            </button>
            <button
              v-if="!isAnswerRight && !isExerciseCompleted && !isAnswerWrong"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              @click="submitAnswer"
            >
              <span class="shad"></span><span>Check</span>
            </button>
            <button
              v-else-if="isAnswerRight && !isExerciseCompleted"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              @click="submitAnswer"
            >
              <span class="shad"></span><span>Next</span>
            </button>
            <!-- <router-link
              v-else-if="isAnswerRight && !isExerciseCompleted"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              :to="{
                name: 'lessons',
                params: {
                  unit_id: this.$route.params.lesson_id,
                },
              }"
              ><span class="shad"></span><span>Go To Lessons</span></router-link
            > -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="bg_6 h_100 position-relative"
    v-else-if="isAnswerRight && isExerciseCompleted"
  >
    <div class="top_area">
      <div class="container"></div>
    </div>

    <div class="container">
      <div class="">
        <div class="container py-5">
          <div class="text-center congratulation_section">
            <h1>Congrats</h1>
            <h2>Well Done</h2>
            <h4>I AM SO PROUD OF YOU</h4>
            <div class="my-3 text-center">
              <router-link
                class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
                :to="{
                  name: 'lesson',
                  params: {
                    unit_id: this.$route.params.lesson_id,
                  },
                }"
                ><span class="shad"></span
                ><span>Go To Lessons</span></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppExcersiceFooter.vue";
import StoryTimeService from "../../services/story-time.service.js";
import { getNextStoryTime } from "../../helpers/GetNextStoryTime.js";
export default {
  name: "StoryExercise7View",
  components: { AppFooter, AppHeader },
  data() {
    return {
      loading: false,
      lessons: null,
      exercise_id: 8,
      practice_complete: false,
      exercise_complete: false,
      correct_answer: "",
      isAnswerWrong: false,
      isAnswerRight: false,
      isAnswerDisabled: false,
      isExerciseCompleted: false,
      nextExercise: "",
      questionList: [],
      answerForSubmit: [],
      checkboxChecked: false,
      recheckButton: false,
      content: {
        sentences: [
          {
            index: 0,
            sentence: "John is a writer. /question/",
          },
          {
            index: 1,
            sentence: "Laura is a mother /question/",
          },
          {
            index: 2,
            sentence: "Carly has blonde hair. /negative/",
          },
        ],
      },
    };
  },
  mounted() {
    this.loadQuestion();
  },
  methods: {
    async loadQuestion() {
      const data = {};
      this.answerForSubmit = [];
      this.loading = false;
      this.isAnswerRight = false;
      this.isAnswerDisabled = false;
      this.checkboxChecked = false;
      this.recheckButton = false;
      data["exercise_id"] = this.exercise_id;
      let response = await StoryTimeService.getExerciseContent(
        this.$route.params.storytime_id,
        data
      );
      this.questionList = response.data.content.sentences;
      // this.questionList = this.content.sentences;
      console.log(response);
      this.nextExercise = await getNextStoryTime(
        this.$route.params.lesson_id,
        this.$route.params.storytime_id,
        1
      );
      this.nextExercise = data["exercise_id"] + 1;
    },
    submitAnswer() {
      let valCount = 0;
      let totalCount = 0;
      this.answerForSubmit = [];
      document.querySelectorAll(".answerData").forEach((element) => {
        if (element.value == "") {
          valCount++;
        }
        this.answerForSubmit.push(element.value);
        totalCount++;
      });
      console.log(valCount + " - " + totalCount);
      // console.log(this.answerForSubmit);
      const data = {};
      data["exercise_id"] = this.exercise_id;
      data["answer"] = Object.assign({}, this.answerForSubmit);
      StoryTimeService.submitExerciseAnswer(
        this.$route.params.storytime_id,
        data
      ).then((response) => {
        console.log(response);
        this.practice_complete = response.data.completed_storytime;
        this.exercise_complete = response.data.completed_exercise;
        this.correct_answer = response.data.valid_answer;
        if (this.correct_answer && !this.exercise_complete) {
          this.isAnswerRight = true;
          this.isAnswerWrong = false;
        }
        if (!this.correct_answer) {
          this.isAnswerRight = false;
          this.isAnswerWrong = true;
        }
        if (this.exercise_complete) {
          this.isExerciseCompleted = true;
          this.isAnswerWrong = false;
          this.isAnswerRight = true;
          // this.loadQuestion();
        }
      });
    },
  },
};
</script>
