<template>
  <div
    class="login bg_9 h_100 position-relative justify-conten-center d-flex align-items-center edit-profile"
  >
    <div class="container">
      <div class="col-sm-10 col-10 m-auto col-md-10 col-lg-6">
        <div class="card position-relative">
          <div class="sky_top"><img src="@/assets/sky_white.png" /></div>
          <div class="sky_bottom"><img src="@/assets/sky_white.png" /></div>
          <div class="logo text-center"><img src="@/assets/logo2.png" /></div>
          <h5 class="text-center">Select Language</h5>
          <Form @submit="updateLanguage" :validation-schema="schema">
            <div class="mb-3">
              <label for="from_language" class="form-label"
                >From language</label
              >
              <Field name="from_language" type="text" class="form-control" />
              <ErrorMessage
                name="from_language"
                class="error-feedback text-danger"
              />
            </div>
            <div class="mb-3">
              <label for="to_language" class="form-label">To language</label>
              <Field name="to_language" type="text" class="form-control" />
              <ErrorMessage
                name="to_language"
                class="error-feedback text-danger"
              />
            </div>
            <button class="btn btn-danger w-100 mt-5">
              <!-- <span v-show="loading" class="spinner-border spinner-border-sm"></span> -->
              <span>Update</span>
            </button>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import AppFooter from "@/components/AppFooter.vue";
import UserService from "../services/user.service";
import ToastService from "../services/toast.service";
export default {
  name: "LanguageView",
  components: {
    AppFooter,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      from_language: yup.string().required("Please enter from language!"),
      to_language: yup.string().required("Please enter to language!"),
    });
    return {
      loading: false,
      schema,
    };
  },
  methods: {
    updateLanguage(data, { resetForm }) {
      UserService.updateLanguage(data).then((response) => {
        console.log(response);
        this.message = "";
        resetForm();
        ToastService.toast().success("Language Selected Successfully.");
        this.$router.push("/profile");
      });
    },
  },
};
</script>
