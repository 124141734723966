<template>
  <a
    v-if="this.$store.state.auth.status.loggedIn && this.visibleBackButton"
    href="javascript:;"
    @click="goBack"
    class="backbutton"
  >
    <i class="fas fa-angle-left"></i>
  </a>
  <div class="sidemenubar">
    <i
      v-if="this.$store.state.auth.status.loggedIn"
      @click="visibleLeft = true"
      class="pi pi-bars"
      style="font-size: 2rem"
    ></i>

    <SideBar v-model:visible="visibleLeft" :baseZIndex="10000">
      <div class="profile">
        <img src="@/assets/profile_img2.png" />
        <h4>
          {{ this.$store.state.auth.profile?.first_name }}
          {{ this.$store.state.auth.profile?.last_name }}
        </h4>
      </div>
      <ul class="nav flex-column">
        <li
          class="nav-item"
          :class="this.$route.name == 'profile' ? 'active' : ''"
        >
          <router-link to="/profile" class="nav-link">Profile</router-link>
        </li>
        <li
          class="nav-item"
          :class="this.$route.name == 'my-courses' ? 'active' : ''"
        >
          <router-link to="/my-courses" class="nav-link"
            >My courses</router-link
          >
        </li>
        <li
          class="nav-item"
          :class="this.$route.name == 'my-courses' ? active : ''"
        >
          <a
            @click="logout"
            v-if="this.$store.state.auth.status.loggedIn"
            href="javascript:;"
            class="nav-link"
            >Logout</a
          >
        </li>
      </ul>
    </SideBar>
  </div>
  <footer class="footer">
    <div class="container text-center">
      <!-- <router-link to="/reset-password-link">Reset password</router-link> -->
      <!-- <router-link to="/reset-password">Reset password</router-link> -->
      <!-- <router-link to="/contact">Contact</router-link>
      <router-link to="/terms-conditions">Terms & Conditions</router-link>
      <router-link to="/privacy-policy">Privacy Policy</router-link>
      <router-link to="/support">Support</router-link> -->
      <a href="/contact">Contact</a>
      <a href="/terms-conditions">Terms & Conditions</a>
      <a href="/privacy-policy">Privacy Policy</a>
      <a href="/support">Support</a>

      <a
        @click="logout"
        v-if="this.$store.state.auth.status.loggedIn"
        href="javascript:;"
        >Logout</a
      >
      <a v-if="!this.$store.state.auth.status.loggedIn" href="/">Login</a>
    </div>
  </footer>
</template>
<script>
import { useRoute } from "vue-router";
export default {
  name: "AppFooter",
  components: {},
  data() {
    return {
      visibleBackButton: false,
      visibleLeft: false,
      goBackRouteName: "",
      goBackRouteUrl: "",
    };
  },
  mounted() {
    const route = useRoute();
    if (this.$route.params.unit_id != undefined) {
      // localStorage.removeItem("unit_id");
      localStorage.setItem("unit_id", this.$route.params.unit_id);
    } else if (this.$route.params.course_id != undefined) {
      // localStorage.removeItem("course_id");
      localStorage.setItem("course_id", this.$route.params.course_id);
    } else if (this.$route.params.lesson_id != undefined) {
      // localStorage.removeItem("lesson_id");
      localStorage.setItem("lesson_id", this.$route.params.lesson_id);
    }

    console.log("route", route);
    console.log("get Id ", this.$route);
    // console.log("get Id1 ", localStorage.getItem("unit_id"));
    // console.log("which Route", this.$router);

    this.goBackRouteName = route.matched[0].name;
    if (Object.keys(route.params).length > 0) {
      this.visibleBackButton = true;
    } else {
      this.visibleBackButton = false;
    }
  },
  methods: {
    goBack() {
      console.log("this.$router.params : ", this.$router);
      // this.$router.go(-1);
      console.log("storedData ", localStorage);
      if (this.goBackRouteName == "units") {
        // this.$router.push({
        //   path: "/my-courses",
        // });
        this.$router.push("/my-courses");
      } else if (this.goBackRouteName == "lessons") {
        // this.$router.push({
        //   path: "/units",
        //   params: {
        //     course_id: localStorage.getItem("course_id"),
        //   },
        // });
        this.$router.push("/units/" + localStorage.getItem("course_id"));
      } else if (this.goBackRouteName == "lesson") {
        this.$router.push("/lessons/" + localStorage.getItem("unit_id"));
        // this.$router.push({
        //   path: "/lessons",
        //   params: {
        //     unit_id: localStorage.getItem("unit_id"),
        //   },
        // });
      } else if (this.goBackRouteName == "practicings") {
        this.$router.push("/lesson/" + localStorage.getItem("lesson_id"));
      } else if (this.goBackRouteName == "explanations") {
        this.$router.push("/lesson/" + localStorage.getItem("lesson_id"));
      } else if (this.goBackRouteName == "storys") {
        this.$router.push("/lesson/" + localStorage.getItem("lesson_id"));
      }
    },
    logout() {
      this.userPassError = false;
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/");
      });
    },
  },
};
</script>
