import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import LoginView from "../views/LoginView.vue";
import FindOutMoreView from "../views/FindOutMoreView.vue";
import ContactView from "../views/ContactView.vue";
import PrivacyPolicyView from "../views/PrivacyPolicyView.vue";
import TermsConditionsView from "../views/TermsConditionsView.vue";
import SupportView from "../views/SupportView.vue";
import ResetPasswordView from "../views/ResetPasswordView.vue";
import ResetPasswordLinkView from "../views/ResetPasswordLinkView.vue";

import MyCoursesView from "../views/MyCoursesView.vue";
import CongratsView from "../views/CongratsView.vue";
import ExplanationsView from "../views/ExplanationsView.vue";
import QuizView from "../views/QuizView.vue";
import GiftView from "../views/GiftView.vue";
import LanguageView from "../views/LanguageView.vue";
import LessonsView from "../views/LessonsView.vue";
import LessonView from "../views/LessonView.vue";
import PracticeExerciseView from "../views/PracticeExerciseView.vue";
import PracticingsView from "../views/PracticingsView.vue";

import Exercise1View from "../views/exercise-practice/Exercise1View.vue";
import Exercise2View from "../views/exercise-practice/Exercise2View.vue";
import Exercise3View from "../views/exercise-practice/Exercise3View.vue";
import Exercise4View from "../views/exercise-practice/Exercise4View.vue";
import Exercise5View from "../views/exercise-practice/Exercise5View.vue";
import Exercise6View from "../views/exercise-practice/Exercise6View.vue";
import Exercise7View from "../views/exercise-practice/Exercise7View.vue";

import ProfileView from "../views/ProfileView.vue";
import ProfileEditView from "../views/ProfileEditView.vue";
import StoryExerciseView from "../views/StoryExerciseView.vue";
import StorysView from "../views/StorysView.vue";
import StoryExercise1View from "../views/exercise-story/StoryExercise1View.vue";
import StoryExercise2View from "../views/exercise-story/StoryExercise2View.vue";
import StoryExercise3View from "../views/exercise-story/StoryExercise3View.vue";
import StoryExercise4View from "../views/exercise-story/StoryExercise4View.vue";
import StoryExercise5View from "../views/exercise-story/StoryExercise5View.vue";
import StoryExercise6View from "../views/exercise-story/StoryExercise6View.vue";
import StoryExercise7View from "../views/exercise-story/StoryExercise7View.vue";
import StoryExercise8View from "../views/exercise-story/StoryExercise8View.vue";

import UnitsView from "../views/UnitsView.vue";

const routes = [
  {
    path: "/",
    name: "login",
    component: LoginView,
    meta: { guest: true },
  },
  {
    path: "/login",
    name: "thelogin",
    component: LoginView,
    meta: { guest: true },
  },
  {
    path: "/find-out-more",
    name: "find-out-more",
    component: FindOutMoreView,
    meta: { guest: true },
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
    meta: { requiresAuth: false },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicyView,
    meta: { requiresAuth: false },
  },
  {
    path: "/terms-conditions",
    name: "terms-conditions",
    component: TermsConditionsView,
    meta: { requiresAuth: false },
  },
  {
    path: "/support",
    name: "support",
    component: SupportView,
    meta: { requiresAuth: false },
  },

  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPasswordView,
    meta: { guest: true },
  },

  {
    path: "/reset-password-link",
    name: "reset-password-link",
    component: ResetPasswordLinkView,
    meta: { guest: true },
  },

  {
    path: "/my-courses",
    name: "my-courses",
    component: MyCoursesView,
    meta: { requiresAuth: true },
  },
  {
    path: "/congrats",
    name: "congrats",
    component: CongratsView,
    meta: { requiresAuth: true },
  },

  {
    path: "/explanations/:lesson_id",
    name: "explanations",
    component: ExplanationsView,
    meta: { requiresAuth: true },
  },
  {
    path: "/quiz/:quiz_id",
    name: "quiz",
    component: QuizView,
    meta: { requiresAuth: true },
  },
  {
    path: "/gift",
    name: "gift",
    component: GiftView,
    meta: { requiresAuth: true },
  },
  {
    path: "/language",
    name: "language",
    component: LanguageView,
    meta: { requiresAuth: true },
  },

  {
    path: "/lessons/:unit_id",
    name: "lessons",
    component: LessonsView,
    meta: { requiresAuth: true },
  },

  {
    path: "/lesson/:lesson_id",
    name: "lesson",
    component: LessonView,
    meta: { requiresAuth: true },
  },

  {
    path: "/practice-exercise",
    name: "practice-exercise",
    component: PracticeExerciseView,
    meta: { requiresAuth: true },
  },

  {
    path: "/practicings/:lesson_id",
    name: "practicings",
    component: PracticingsView,
    meta: { requiresAuth: true },
  },

  {
    path: "/practice-exercise-1/:lesson_id/:practice_id",
    name: "practice-exercise-1",
    component: Exercise1View,
    meta: { requiresAuth: true },
  },
  {
    path: "/practice-exercise-2/:lesson_id/:practice_id",
    name: "practice-exercise-2",
    component: Exercise2View,
    meta: { requiresAuth: true },
  },
  {
    path: "/practice-exercise-3/:lesson_id/:practice_id",
    name: "practice-exercise-3",
    component: Exercise3View,
    meta: { requiresAuth: true },
  },
  {
    path: "/practice-exercise-4/:lesson_id/:practice_id",
    name: "practice-exercise-4",
    component: Exercise4View,
    meta: { requiresAuth: true },
  },
  {
    path: "/practice-exercise-5/:lesson_id/:practice_id",
    name: "practice-exercise-5",
    component: Exercise5View,
    meta: { requiresAuth: true },
  },
  {
    path: "/practice-exercise-6/:lesson_id/:practice_id",
    name: "practice-exercise-6",
    component: Exercise6View,
    meta: { requiresAuth: true },
  },
  {
    path: "/practice-exercise-7/:lesson_id/:practice_id",
    name: "practice-exercise-7",
    component: Exercise7View,
    meta: { requiresAuth: true },
  },

  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
    meta: { requiresAuth: true },
  },

  {
    path: "/profile-edit",
    name: "profile-edit",
    component: ProfileEditView,
    meta: { requiresAuth: true },
  },

  {
    path: "/storys/:lesson_id",
    name: "storys",
    component: StorysView,
    meta: { requiresAuth: true },
  },

  {
    path: "/story-exercise",
    name: "story-exercise",
    component: StoryExerciseView,
    meta: { requiresAuth: true },
  },

  {
    path: "/story-exercise-1/:lesson_id/:storytime_id",
    name: "story-exercise-1",
    component: StoryExercise1View,
    meta: { requiresAuth: true },
  },

  {
    path: "/story-exercise-2/:lesson_id/:storytime_id",
    name: "story-exercise-2",
    component: StoryExercise2View,
    meta: { requiresAuth: true },
  },
  {
    path: "/story-exercise-3/:lesson_id/:storytime_id",
    name: "story-exercise-3",
    component: StoryExercise3View,
    meta: { requiresAuth: true },
  },
  {
    path: "/story-exercise-4/:lesson_id/:storytime_id",
    name: "story-exercise-4",
    component: StoryExercise4View,
    meta: { requiresAuth: true },
  },
  {
    path: "/story-exercise-5/:lesson_id/:storytime_id",
    name: "story-exercise-5",
    component: StoryExercise5View,
    meta: { requiresAuth: true },
  },
  {
    path: "/story-exercise-6/:lesson_id/:storytime_id",
    name: "story-exercise-6",
    component: StoryExercise6View,
    meta: { requiresAuth: true },
  },
  {
    path: "/story-exercise-7/:lesson_id/:storytime_id",
    name: "story-exercise-7",
    component: StoryExercise7View,
    meta: { requiresAuth: true },
  },
  {
    path: "/story-exercise-8/:lesson_id/:storytime_id",
    name: "story-exercise-8",
    component: StoryExercise8View,
    meta: { requiresAuth: true },
  },

  {
    path: "/units/:course_id",
    name: "units",
    component: UnitsView,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.auth.status.loggedIn) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.state.auth.status.loggedIn) {
      next("/my-courses");
      return;
    }
    next();
  } else {
    next();
  }
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ["/"];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem("user");
//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next("/");
//   } else {
//     next();
//   }
// });

export default router;
