<template>
  <div
    v-for="unit in result"
    :key="unit.name"
    class="col-sm-4 col-12 col-lg-4 unit"
  >
    <div class="unit unit1 text-center">
      <h4 class="">{{ unit.name }}</h4>
      <div class="clipimg">
        <img v-if="unit.image == ''" width="" src="../assets/noimage.png" />
        <img v-else width="" :src="unit.image" />
      </div>
      <p>
        <router-link
          v-if="!unit.locked"
          :to="{
            name: 'lessons',
            params: {
              unit_id: unit.id,
            },
          }"
          class="bg_green"
        >
          Click to Enter
        </router-link>
        <a v-else href="javascript:;" class="bg_red text-white lock-box">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            class="bi bi-lock-fill"
            viewBox="0 0 20 20"
          >
            <path
              d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"
            />
          </svg>
          Not available yet
        </a>
      </p>
    </div>
  </div>
  <div
    v-if="result && result.length == 0"
    class="col-sm-12 col-12 col-lg-12 text-center"
  >
    <h5>No lesson</h5>
  </div>
  <div class="my-3 text-center">
    <button
      v-if="prevButton"
      @click="prev"
      class="btn texture_btn btn-warning btn-sm circle_texture me-3"
    >
      <span class="shad"></span><span>Prev</span>
    </button>
    <button
      v-if="nextButton"
      @click="next"
      class="btn texture_btn btn-warning btn-sm circle_texture"
    >
      <span class="shad"></span><span>Next</span>
    </button>
  </div>
</template>
<script>
import { ref } from "vue";
import UnitService from "../services/unit.service";
import { useArrayPagination } from "vue-composable";
export default {
  name: "UnitCard",
  props: {
    course_id: Number,
  },
  computed: {
    prevButton() {
      return this.currentPage == 1 && this.pageSize >= this.lastPage
        ? false
        : true;
    },
    nextButton() {
      return this.currentPage == this.lastPage && this.pageSize >= this.lastPage
        ? false
        : true;
    },
  },
  async setup(props) {
    const units = ref(null);
    let response = await UnitService.getUnits(props.course_id);
    units.value = await response.data.units;
    const { next, prev, currentPage, lastPage, result, pageSize, offset } =
      useArrayPagination(units, { pageSize: 6 });
    return {
      next,
      prev,
      currentPage,
      lastPage,
      pageSize,
      result,
      offset,
    };
  },
};
</script>
