<template>
  <div class="storypage bg_2 position-relative">
    <AppHeader></AppHeader>

    <!-- <div class="childer-imgs">
      <div class="chil_img1">
        <img src="@/assets/pencil_child.png" />
      </div>
      <div class="chil_img2">
        <img src="@/assets/laptop_girl.png" />
      </div>
      <div class="chil_img3">
        <img src="@/assets/half_book.png" />
      </div>
      <div class="chil_img4">
        <img src="@/assets/book_readign.png" />
      </div>
    </div> -->
    <div class="container">
      <div class="">
        <div class="heading-lg text-center">
          <h1 class="heading_2">EXERCISE 4</h1>
        </div>
        <!-- <p class="heading_sm_2 mt-5">
          Type the missing letters to complete the story
        </p> -->
        <div class="girl_section mx-auto">
          <div class="bg-white p-4 storyexercise4">
            <div class="row">
              <h5 class="mb-4 col-12 text-center">True Or False?</h5>

              <div class="text-center my-3">
                <img v-if="isAnswerWrong" width="75" src="@/assets/devil.svg" />
                <img
                  v-if="isAnswerRight"
                  class="me-2"
                  width="75"
                  src="@/assets/smile.svg"
                />
              </div>
              <form>
                <div class="sentences">
                  <div class="row">
                    <h4 class="col-lg-8 col-6 mb-4">Sentences</h4>
                    <h4 class="mb-4 text-center col-3 col-lg-2">True</h4>
                    <h4 class="mb-4 text-center col-3 col-lg-2">False</h4>
                  </div>
                  <div
                    class="row"
                    v-for="(queStatement, index) in statementsList"
                    :key="index"
                  >
                    <h6 class="mb-3 col-lg-8 col-6">
                      {{ index + 1 }}) {{ queStatement.statement }}.
                    </h6>

                    <h6
                      class="mb-2 justify-content-center text-align-center d-flex col-lg-2 col-3"
                    >
                      <div class="custom-radio d-flex">
                        <div>
                          <input
                            v-bind:id="'check2_' + index"
                            class="radio-custom"
                            v-bind:name="'radio-group' + index"
                            type="radio"
                            value="0"
                            @change="check($event, 1)"
                          /><label
                            v-bind:for="'check2_' + index"
                            class="radio-custom-label"
                          ></label>
                        </div>
                      </div>
                    </h6>
                    <h6
                      class="mb-2 justify-content-center text-align-center d-flex col-lg-2 col-3"
                    >
                      <div class="custom-radio d-flex">
                        <div>
                          <input
                            v-bind:id="'check1_' + index"
                            class="radio-custom"
                            v-bind:name="'radio-group' + index"
                            type="radio"
                            value="0"
                            @change="check($event, 0)"
                          /><label
                            v-bind:for="'check1_' + index"
                            class="radio-custom-label"
                          ></label>
                        </div>
                      </div>
                    </h6>
                  </div>
                  <!-- <div class="row">
                    <h6 class="mb-3 col-sm-6">1) John Is a great dog.</h6>

                    <h6
                      class="mb-2 justify-content-center text-align-center d-flex col-2"
                    >
                      <div class="check-box">
                        <input type="checkbox" id="check1" />
                        <label for="check1"></label>
                      </div>
                    </h6>
                    <h6
                      class="mb-2 justify-content-center text-align-center d-flex col-2"
                    >
                      <div class="check-box">
                        <input type="checkbox" id="check25" />
                        <label for="check25"></label>
                      </div>
                    </h6>
                    <h4 class="mb-2 text-center col-2">
                      <img class="me-2" width="32" src="@/assets/devil.svg" />
                    </h4>
                  </div>
                  <div class="row">
                    <h6 class="mb-3 col-sm-6">2) Laura is woman.</h6>
                    <h6
                      class="mb-2 justify-content-center text-align-center d-flex col-2"
                    >
                      <div class="check-box">
                        <input type="checkbox" id="check11" />
                        <label for="check11"></label>
                      </div>
                    </h6>
                    <h6
                      class="mb-2 justify-content-center text-align-center d-flex col-2"
                    >
                      <div class="check-box">
                        <input type="checkbox" id="check251" />
                        <label for="check251"></label>
                      </div>
                    </h6>
                    <h4 class="mb-2 text-center col-2">
                      <img class="me-2" width="32" src="@/assets/devil.svg" />
                    </h4>
                  </div>
                  <div class="row">
                    <h6 class="mb-3 col-sm-6">3) Carly has red hair.</h6>
                    <h6
                      class="mb-2 justify-content-center text-align-center d-flex col-2"
                    >
                      <div class="check-box">
                        <input type="checkbox" id="check12" />
                        <label for="check12"></label>
                      </div>
                    </h6>
                    <h6
                      class="mb-2 justify-content-center text-align-center d-flex col-2"
                    >
                      <div class="check-box">
                        <input type="checkbox" id="check252" />
                        <label for="check252"></label>
                      </div>
                    </h6>
                    <h4 class="mb-2 text-center col-2">
                      <img class="me-2" width="32" src="@/assets/devil.svg" />
                    </h4>
                  </div>
                  <div class="row">
                    <h6 class="mb-3 col-sm-6">4) Jesse has a body.</h6>
                    <h6
                      class="mb-2 justify-content-center text-align-center d-flex col-2"
                    >
                      <div class="check-box">
                        <input type="checkbox" id="check13" />
                        <label for="check13"></label>
                      </div>
                    </h6>
                    <h6
                      class="mb-2 justify-content-center text-align-center d-flex col-2"
                    >
                      <div class="check-box">
                        <input type="checkbox" id="check253" />
                        <label for="check253"></label>
                      </div>
                    </h6>
                    <h4 class="mb-2 text-center col-2">
                      <img class="me-2" width="32" src="@/assets/smile.svg" />
                    </h4>
                  </div>
                  <div class="row">
                    <h6 class="mb-3 col-sm-6">5) Zombie is a dog.</h6>
                    <h6
                      class="mb-2 justify-content-center text-align-center d-flex col-2"
                    >
                      <div class="check-box">
                        <input type="checkbox" id="check14" />
                        <label for="check14"></label>
                      </div>
                    </h6>
                    <h6
                      class="mb-2 justify-content-center text-align-center d-flex col-2"
                    >
                      <div class="check-box">
                        <input type="checkbox" id="check254" />
                        <label for="check254"></label>
                      </div>
                    </h6>
                    <h4 class="mb-2 text-center col-2">
                      <img class="me-2" width="32" src="@/assets/smile.svg" />
                    </h4>
                  </div> -->
                </div>
              </form>
            </div>
          </div>
          <div class="my-2 text-center">
            <button
              v-if="isAnswerWrong"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              @click="$router.go($router.currentRoute)"
            >
              <span class="shad"></span><span>Try Again</span>
            </button>
            <button
              v-if="!isAnswerRight && !isExerciseCompleted && !isAnswerWrong"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              @click="submitAnswer"
            >
              <span class="shad"></span><span>Check</span>
            </button>

            <button
              v-else-if="isAnswerRight && !isExerciseCompleted"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              @click="submitAnswer"
            >
              <span class="shad"></span><span>Next</span>
            </button>

            <router-link
              v-else-if="isAnswerRight && isExerciseCompleted"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              :to="{
                name: 'story-exercise-' + nextExercise,
                params: {
                  lesson_id: this.$route.params.lesson_id,
                  storytime_id: this.$route.params.storytime_id,
                },
              }"
              ><span class="shad"></span><span>Next Exercise</span></router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppExcersiceFooter.vue";
import StoryTimeService from "../../services/story-time.service.js";
import { getNextStoryTime } from "../../helpers/GetNextStoryTime.js";
export default {
  name: "StoryExercise4View",
  components: { AppFooter, AppHeader },
  data() {
    return {
      loading: false,
      lessons: null,
      practice_complete: false,
      exercise_complete: false,
      correct_answer: "",
      isAnswerWrong: false,
      isAnswerRight: false,
      isAnswerDisabled: false,
      isExerciseCompleted: false,
      nextExercise: "",
      statementsList: "",
      answerForSubmit: [],
      checkboxChecked: false,
      exercise_id: 4,
      contents: {
        statements: [
          {
            statement: "This is False!",
            index: 0,
          },
          {
            statement: "This is True!",
            index: 1,
          },
        ],
      },
    };
  },
  mounted() {
    // this.loadQuestion();
  },
  created() {
    this.loadQuestion();
  },
  methods: {
    async loadQuestion() {
      const data = {};
      this.answerForSubmit = [];
      this.loading = false;
      this.isAnswerRight = false;
      this.isAnswerDisabled = false;
      this.checkboxChecked = false;
      data["exercise_id"] = this.exercise_id;
      let response = await StoryTimeService.getExerciseContent(
        this.$route.params.storytime_id,
        data
      );
      console.log(response);
      this.statementsList = response.data.content.statements;
      this.nextExercise = await getNextStoryTime(
        this.$route.params.lesson_id,
        this.$route.params.storytime_id,
        1
      );
      this.nextExercise = data["exercise_id"] + 1;
    },
    check(e, value11) {
      var checkboxId = e.target.id;
      var fields = checkboxId.split("_");
      var checkIndex = 0;
      if (fields[1] == "0") {
        checkIndex = 0;
      } else if (fields[1] == "1") {
        checkIndex = 1;
      }
      if (this.answerForSubmit.length < this.statementsList.length) {
        this.answerForSubmit.push(checkIndex);
      }
      for (var key in this.answerForSubmit) {
        if (checkIndex == key) {
          // var value = 0;
          // if (e.target.value == "0") {
          //   value = 0;
          // } else {
          //   value = 1;
          // }
          if (e.target.checked == true) {
            this.answerForSubmit[key] = value11;
            console.log(value11);
          }
        }
      }
      console.log(this.answerForSubmit);
    },
    submitAnswer() {
      const data = {};
      data["exercise_id"] = this.exercise_id;
      data["answer"] = Object.assign({}, this.answerForSubmit);
      StoryTimeService.submitExerciseAnswer(
        this.$route.params.storytime_id,
        data
      ).then((response) => {
        console.log(response);
        this.practice_complete = response.data.completed_storytime;
        this.exercise_complete = response.data.completed_exercise;
        this.correct_answer = response.data.valid_answer;
        if (this.correct_answer && !this.exercise_complete) {
          this.isAnswerRight = true;
          this.isAnswerWrong = false;
        }
        if (!this.correct_answer) {
          this.isAnswerRight = false;
          this.isAnswerWrong = true;
        }
        if (this.exercise_complete) {
          this.isExerciseCompleted = true;
          this.isAnswerWrong = false;
          this.isAnswerRight = true;
          // this.loadQuestion();
        }
      });
    },
  },
};
</script>
