import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

const user = JSON.parse(localStorage.getItem("user"));
const profile = JSON.parse(localStorage.getItem("profile"));
const initialState = user
  ? { status: { loggedIn: true }, user, profile }
  : { status: { loggedIn: false }, user: null, profile: null };

export const auth = {
  namespaced: true,
  state: initialState,
  getters: {
    user(state) {
      return state.status.user;
    },
    profile(state) {
      return state.status.profile;
    },
  },
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        async (user) => {
          return await UserService.getProfile().then((profile) => {
            commit("loginSuccess", { user: user, profile: profile });
            return Promise.resolve(user);
          });
          // commit("loginSuccess", { user: user, profile: {} });
          // return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        (response) => {
          commit("registerSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    loginSuccess(state, data) {
      state.status.loggedIn = true;
      state.user = data.user;
      state.profile = data.profile;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
      state.profile = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
  },
};
