<template>
  <div class="bg_1 h_100 position-relative">
    <AppHeader></AppHeader>
    <div class="container py-5">
      <div class="text-center">
        <div class="mb-5" v-for="(question, index) in contentList" :key="index">
          <button
            v-if="question.locked == false"
            type="button"
            @click="goTo(question.link)"
            v-bind:class="question.classUse"
            v-bind:id="'1_' + index"
          >
            {{ question.text }}
          </button>

          <button
            v-else-if="question.locked == true"
            type="button"
            v-bind:class="question.classUse"
            v-bind:id="'2_' + index"
          >
            {{ question.text }}
          </button>

          <div v-if="question.locked == false" class="text-msg text-success">
            Click to Access
          </div>
          <div v-else-if="question.locked == true" class="text-msg text-danger">
            <i class="fas fa-lock me-1"></i> Not Accessible
          </div>
        </div>

        <!-- <div class="mb-5">
              <button
                type="button"
                @click="goTo('practicings')"
                class="btn texture_btn btn-success btn-lg cancel_texture"
              >
                Play & Practice
              </button>
              <div class="text-msg text-success">Click to Access</div>
            </div>
            
            <div class="mb-5">
              <button
                @click="goTo('storys')"
                class="btn texture_btn btn-info btn-lg heart_texture"
              >
                <span class="shad"></span><span>Story Time</span>
              </button>
              <div class="text-msg text-success">Click to Access</div>
            </div> -->

        <!-- <div class="mb-5">
          <button
            type="button"
            @click="goTo('explanations')"
            class="btn texture_btn btn-warning btn-lg circle_texture"
          >
            Explanations
          </button>

          <div class="text-msg text-success">Click to Access</div>
        </div>
        <div class="mb-5" v-if="practiceLockCheck == true">
          <button
            type="button"
            class="btn texture_btn btn-success btn-lg cancel_texture"
          >
            Play & Practice
          </button>
          <div class="text-msg text-danger">
            <i class="fas fa-lock me-1"></i> Not Accessible
          </div>
        </div>
        <div class="mb-5" v-else-if="practiceLockCheck == false">
          <button
            type="button"
            @click="goTo('practicings')"
            class="btn texture_btn btn-success btn-lg cancel_texture"
          >
            Play & Practice
          </button>
          <div class="text-msg text-success">Click to Access</div>
        </div>
        <div class="mb-5" v-if="storytimeLockCheck == true">
          <button class="btn texture_btn btn-info btn-lg heart_texture">
            <span class="shad"></span><span>Story Time</span>
          </button>
          <div class="text-msg text-danger">
            <i class="fas fa-lock me-1"></i> Not Accessible
          </div>
        </div>
        <div class="mb-5" v-else-if="storytimeLockCheck == false">
          <button
            @click="goTo('storys')"
            class="btn texture_btn btn-info btn-lg heart_texture"
          >
            <span class="shad"></span><span>Story Time</span>
          </button>
          <div class="text-msg text-success">Click to Access</div>
        </div> -->
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
import ExplanationService from "../services/explanation.service";
import StoryTimeService from "./../services/story-time.service.js";
import PractiseService from "./../services/practise.service";
import LessonService from "./../services/lesson.service";
export default {
  name: "LessonView",
  components: { AppFooter, AppHeader },
  data() {
    return {
      storytimeLockCheck: true,
      practiceLockCheck: true,
      explanationLockCheck: true,
      isExplanationCompleted: false,
      isPracticeCompleted: false,
      isStoryCompleted: false,
      contentList: "",
      content: {
        sentences: [
          {
            index: 0,
            text: "Explanations",
            link: "explanations",
            classUse: "btn texture_btn btn-warning btn-lg circle_texture",
            locked: false,
            uniqueText: "explanations",
          },
          {
            index: 1,
            text: "Play & Practice",
            link: "practicings",
            classUse: "btn texture_btn btn-success btn-lg cancel_texture",
            locked: true,
            uniqueText: "practicings",
          },
          {
            index: 2,
            text: "Story Time",
            link: "storys",
            classUse: "btn texture_btn btn-info btn-lg heart_texture",
            locked: true,
            uniqueText: "storys",
          },
        ],
      },
    };
  },
  mounted() {
    this.loadQuestion();
    this.getCheckExplanations();
    // this.getCheckUnlockStoryTime();
    // this.getCheckUnlockPractice();
  },
  methods: {
    loadQuestion() {
      this.contentList = this.content.sentences;
    },
    goTo(name) {
      this.$router.push({
        name: name,
        params: { lesson_id: this.$route.params.lesson_id },
      });
    },

    async getCheckExplanations() {
      this.explanationLockCheck = true;
      let response = await ExplanationService.getExplanations(
        this.$route.params.lesson_id
      );
      let tt = response.data.explanations;
      let check = 0;
      tt.forEach((element) => {
        if (element.locked == false && element.completed == true) {
          check++;
        }
      });
      // console.log("getCheckExplanations", check + " - " + tt.length);
      if (check == tt.length) {
        this.explanationLockCheck = false;
        this.isExplanationCompleted = true;
        this.getCheckUnlockPractice();
      } else {
        this.explanationLockCheck = true;
        this.isExplanationCompleted = false;
        this.getCheckUnlockPractice();
      }
      // console.log(this.isExplanationCompleted);
    },
    async getCheckUnlockPractice() {
      this.practiceLockCheck = true;
      // console.log("check in pra ", this.isExplanationCompleted);
      if (this.isExplanationCompleted == true) {
        let response = await PractiseService.getPractise(
          this.$route.params.lesson_id
        );
        // console.log(response);
        let tt1 = response.data.practices;
        let check1 = 0;
        tt1.forEach((element) => {
          if (element.locked == false && element.completed == true) {
            check1++;
          }
        });
        if (check1 == tt1.length) {
          this.practiceLockCheck = false;
          this.isPracticeCompleted = true;
          this.getCheckUnlockStoryTime();
        } else {
          this.practiceLockCheck = true;
          this.isPracticeCompleted = false;
          this.getCheckUnlockStoryTime();
        }
        this.contentList.forEach((element) => {
          if (element.uniqueText == "practicings") {
            element.locked = false;
          }
        });
      }
    },
    async getCheckUnlockStoryTime() {
      this.storytimeLockCheck = true;
      // console.log(
      //   this.isExplanationCompleted + " - " + this.isPracticeCompleted
      // );
      if (
        this.isExplanationCompleted == true &&
        this.isPracticeCompleted == true
      ) {
        this.storytimeLockCheck = false;
      } else {
        this.storytimeLockCheck = true;
      }
      if (this.storytimeLockCheck == false) {
        let response = await StoryTimeService.getStoryTime(
          this.$route.params.lesson_id
        );
        // console.log("ste ", response);
        let tt = response.data.storytimes;
        let check = 0;
        tt.forEach((element) => {
          if (element.locked == false && element.completed == true) {
            check++;
          }
        });
        var data = {};
        if (check == tt.length) {
          // this.storytimeLockCheck = false;
          // console.log("complete true");
          if (this.$route.params.lesson_id != undefined) {
            data["type"] = "lesson";
            data["id"] = this.$route.params.lesson_id;
            data["action"] = "unlock";
            LessonService.getUnlockNextLessons(data).then((response) => {
              console.log("My Respo", response);
            });
          }
          // alert(this.$route.params.lesson_id);
        } else {
          // console.log("Not completed");
          // this.storytimeLockCheck = true;

          if (this.$route.params.lesson_id != undefined) {
            data["type"] = "lesson";
            data["id"] = this.$route.params.lesson_id;
            data["action"] = "unlock";

            LessonService.getUnlockNextLessons(data).then((response) => {
              console.log("My Respo", response);
            });
          }
        }
      }

      this.contentList.forEach((element) => {
        if (element.uniqueText == "storys") {
          element.locked = this.storytimeLockCheck;
        }
      });
    },
  },
};
</script>
