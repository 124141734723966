<template>
  <div
    v-for="lesson in result"
    :key="lesson.name"
    class="col-sm-4 sky sky1 text-center col-12"
  >
    <div class="cloud">
      <svg
        width="100%"
        height="202"
        viewBox="0 0 282 202"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M106 1C81.6 4.2 73.8333 26.3333 73 37C11.4 33 -1 80 0.499997
        104C10.5 148.4 49 159.5 67 159.5C84.6 191.1 108.333 200 118 200.5C146.4
        206.9 176.5 182.833 188 170C253.6 188.4 277 139 280.5 112C279.3 54
        229.333 41.1667 204.5 42C187.7 14.4 165.5 18.1667 156.5 23.5C145.3
        7.10001 136.167 3 129.5 1C122.833 -0.999998 106 1 106 1Z"
          :fill="lesson.color"
        />
        <mask
          id="mask0_2_13"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="1"
          y="0"
          width="281"
          height="202"
        >
          <path
            d="M107 1C82.6 4.2 74.8333 26.3333 74 37C12.4 33 -3.45707e-06 80 1.5 104C11.5 148.4 50 159.5 68 159.5C85.6 191.1 109.333 200 119 200.5C147.4 206.9 177.5 182.833 189 170C254.6 188.4 278 139 281.5 112C280.3 54 230.333 41.1667 205.5 42C188.7 14.4 166.5 18.1667 157.5 23.5C146.3 7.10001 137.167 3 130.5 1C123.833 -0.999998 107 1 107 1Z"
            fill="#FF777A"
          />
        </mask>
        <g mask="url(#mask0_2_13)">
          <circle
            cx="35.5"
            cy="128.5"
            r="142.5"
            transform="rotate(-7.27419 35.5 128.5)"
            fill="white"
            fill-opacity="0.09"
          />
        </g>
      </svg>
    </div>
    <div class="sky-conten">
      <h4 class="text-black">{{ lesson.name }}</h4>
      <div class="clipimg">
        <img v-if="lesson.image == ''" width="" src="../assets/noimage.png" />
        <img v-else width="" :src="lesson.image" />
      </div>
      <p>
        <router-link
          v-if="!lesson.locked"
          :to="{
            name: 'lesson',
            params: {
              lesson_id: lesson.id,
            },
          }"
          class="bg_green"
        >
          Click to Enter
        </router-link>
        <a v-else href="javascript:;" class="bg_red text-white lock-box">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            class="bi bi-lock-fill"
            viewBox="0 0 20 20"
          >
            <path
              d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"
            />
          </svg>
          Not available yet
        </a>
      </p>
    </div>
  </div>
  <div
    v-if="result && result.length == 0"
    class="col-sm-12 col-12 col-lg-12 text-center"
  >
    <h5>No lesson</h5>
  </div>
  <div class="my-3 text-center">
    <button
      v-if="prevButton"
      @click="prev"
      class="btn texture_btn btn-warning btn-sm circle_texture"
    >
      <span class="shad"></span><span>Prev</span>
    </button>
    <button
      v-if="nextButton"
      @click="next"
      class="btn texture_btn btn-warning btn-sm circle_texture ms-3"
    >
      <span class="shad"></span><span>Next</span>
    </button>
  </div>
</template>
<script>
import { ref } from "vue";
import LessonService from "../services/lesson.service";
import { useArrayPagination } from "vue-composable";
export default {
  name: "LessonCard",
  props: {
    unit_id: Number,
  },
  computed: {
    prevButton() {
      return this.currentPage == 1 && this.pageSize >= this.lastPage
        ? false
        : true;
    },
    nextButton() {
      return this.currentPage == this.lastPage && this.pageSize >= this.lastPage
        ? false
        : true;
    },
  },
  async setup(props) {
    const lessons = ref(null);
    let response = await LessonService.getLessons(props.unit_id);
    lessons.value = await response.data.lessons;
    const { next, prev, currentPage, lastPage, result, pageSize, offset } =
      useArrayPagination(lessons, { pageSize: 6 });
    return {
      next,
      prev,
      currentPage,
      lastPage,
      pageSize,
      result,
      offset,
    };
  },
};
</script>
