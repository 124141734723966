<template>
  <div
    v-for="course in result"
    :key="course.name"
    class="col-sm-6 col-12 col-lg-6 course-color"
  >
    <div class="course course1 text-center">
      <h4 class="text-white">{{ course.name }}</h4>
      <div class="clipimg">
        <img v-if="course.image == ''" width="" src="../assets/noimage.png" />
        <img v-else width="" :src="course.image" />
      </div>
      <p>
        <router-link
          v-if="1 == 1 && course.locked == false"
          :to="{
            name: 'units',
            params: {
              course_id: course.id,
            },
          }"
          class="bg_green"
        >
          Click to Enter
        </router-link>
        <a v-else href="javascript:;" class="bg_red text-white lock-box">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            class="bi bi-lock-fill"
            viewBox="0 0 20 20"
          >
            <path
              d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"
            />
          </svg>
          Not available yet
        </a>
      </p>
    </div>
  </div>
  <div class="my-3 text-center">
    <button
      v-if="prevButton"
      @click="prev"
      class="btn texture_btn btn-warning btn-sm circle_texture me-3"
    >
      <span class="shad"></span><span>Prev</span>
    </button>
    <button
      v-if="nextButton"
      @click="next"
      class="btn texture_btn btn-warning btn-sm circle_texture"
    >
      <span class="shad"></span><span>Next</span>
    </button>
  </div>
</template>
<script>
import { ref } from "vue";
import CourseService from "../services/course.service";
import { useArrayPagination } from "vue-composable";
export default {
  name: "CourseCard",
  computed: {
    prevButton() {
      return this.currentPage == 1 && this.pageSize >= this.lastPage
        ? false
        : true;
    },
    nextButton() {
      return this.currentPage == this.lastPage && this.pageSize >= this.lastPage
        ? false
        : true;
    },
  },
  async setup() {
    const array = ref(null);
    let response = await CourseService.getCourses();
    array.value = await response.data.courses;
    const { next, prev, currentPage, lastPage, result, pageSize, offset } =
      useArrayPagination(array, { pageSize: 4 });
    return {
      next,
      prev,
      currentPage,
      lastPage,
      pageSize,
      result,
      offset,
    };
  },
};
</script>
