import axios from "axios";
import router from "../router";
import { useToast } from "vue-toastification";
const toast = useToast();

export function responseInterceptor() {
  axios.interceptors.response.use(
    (response) => {
      if (response.status === 200 || response.status === 201) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    },
    (error) => {
      if (error.response.status) {
        switch (error.response.status) {
          case 400:
            toast.error(error.response.data.error);
            break;
          case 401:
            router.replace("/login");
            break;
          case 403:
            toast.error(error.response.data.error);
            break;
          case 404:
            toast.error(error.response.data.error);
            break;
          case 405:
            toast.error(error.response.data.error);
            break;
          case 500:
            toast.error(error.response.data.error);
            break;
          case 502:
            toast.error(error.response.data.error);
        }
        return Promise.reject(error.response);
      }
    }
  );
}
