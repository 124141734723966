import axios from "axios";

class LessonService {
  getLessons(unitId) {
    return axios.get(process.env.VUE_APP_API + "/get_lessons/" + unitId);
  }

  // To Lock/Unlock Lessons
  getUnlockNextLessons(data) {
    console.log(data);
    return axios.post(
      process.env.VUE_APP_API + "/debug_set_completion/",
      JSON.stringify(data)
    );
  }
}

export default new LessonService();
