<template>
  <div class="storypage bg_2 position-relative">
    <AppHeader></AppHeader>

    <!-- <div class="childer-imgs">
      <div class="chil_img1">
        <img src="@/assets/pencil_child.png" />
      </div>
      <div class="chil_img2">
        <img src="@/assets/laptop_girl.png" />
      </div>
      <div class="chil_img3">
        <img src="@/assets/half_book.png" />
      </div>
      <div class="chil_img4">
        <img src="@/assets/book_readign.png" />
      </div>
    </div> -->
    <div class="container">
      <div class="">
        <div class="heading-lg text-center">
          <h1 class="heading_2">EXERCISE 1</h1>
        </div>

        <div class="girl_section mx-auto">
          <div class="bg-white px-4 pb-4">
            <div class="row">
              <p class="heading_sm_2 mt-3 col-12 text-dark">
                Type the missing letters to complete the story
              </p>
              <div class="col-sm-12 text-center my-3">
                <img class="" width="184" src="@/assets/girlschild.png" />
              </div>
              <div class="text-center my-4">
                <img v-if="isAnswerWrong" width="75" src="@/assets/devil.svg" />
                <img
                  v-if="isAnswerRight"
                  class="me-2"
                  width="75"
                  src="@/assets/smile.svg"
                />
              </div>
              <div class="col-sm-6 text-center">
                <div class="card h-100">
                  <div class="card-header">Original Text</div>
                  <div class="card-body">
                    <p class="card-text">
                      {{ originalContent }}
                    </p>
                    <!-- s -->
                  </div>
                </div>
              </div>
              <div class="col-sm-6 text-center">
                <div class="card">
                  <div class="card-header">Exercise Text</div>
                  <div class="card-body missingContentClass">
                    <p class="card-text" v-html="missingContent"></p>
                    <!-- s -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="my-2 text-center">
            <button
              v-if="isAnswerWrong"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              @click="$router.go($router.currentRoute)"
            >
              <span class="shad"></span><span>Try Again</span>
            </button>
            <button
              v-if="!isAnswerRight && !isExerciseCompleted && !isAnswerWrong"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              @click="submitAnswer"
            >
              <span class="shad"></span><span>Check</span>
            </button>

            <button
              v-else-if="isAnswerRight && !isExerciseCompleted"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              @click="submitAnswer"
            >
              <span class="shad"></span><span>Next</span>
            </button>

            <router-link
              v-else-if="isAnswerRight && isExerciseCompleted"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              :to="{
                name: 'story-exercise-' + nextExercise,
                params: {
                  lesson_id: this.$route.params.lesson_id,
                  storytime_id: this.$route.params.storytime_id,
                },
              }"
              ><span class="shad"></span><span>Next Exercise</span></router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppExcersiceFooter.vue";
import StoryTimeService from "../../services/story-time.service.js";
import { getNextStoryTime } from "../../helpers/GetNextStoryTime.js";
export default {
  name: "StoryExercise1View",
  components: { AppFooter, AppHeader },
  data() {
    return {
      loading: false,
      lessons: null,
      practice_complete: false,
      exercise_complete: false,
      correct_answer: "",
      isAnswerWrong: false,
      isAnswerRight: false,
      answerId: "",
      isAnswerDisabled: false,
      isExerciseCompleted: false,
      nextExercise: "",
      originalContent: "",
      missingContent: "",
      missing_indices: [],
      groupId: "",
      recheckButton: false,
      contents: {
        original:
          "Hi, I am John. I am a writer.\nI am a man and I am a father.\nI am tall. I am strong.\n I am not heavy. I am smart.\nI am athletic",
        missing:
          "H_, I a_ __h_. I am a writer.\nI am a man and I am a father.\nI am tall. I am strong.\n I am not heavy. I am smart.\nI am athletic",
        missing_indices: [1, 7, 9, 10, 12],
      },
    };
  },
  mounted() {
    // this.loadQuestion();
  },
  created() {
    this.loadQuestion();
    // this.test();
  },
  methods: {
    async loadQuestion() {
      const data = {};
      this.recheckButton = false;
      data["exercise_id"] = 1;
      let response = await StoryTimeService.getExerciseContent(
        this.$route.params.storytime_id,
        data
      );
      // console.log(response);
      this.originalContent = response.data.content.original;
      this.missingContent = response.data.content.missing;
      this.missing_indices = response.data.content.missing_indices;
      let htm =
        '<input type="text" style="width: 15px" class="emptyspace" maxlength="1" v-model="groupId"  @keyup="saveInput" />';
      let str = this.missingContent.split("_").join(htm);
      this.missingContent = str;
      this.loading = false;
      this.isAnswerRight = false;
      this.isAnswerDisabled = false;
      this.nextExercise = await getNextStoryTime(
        this.$route.params.lesson_id,
        this.$route.params.storytime_id,
        1
      );
      // console.log("nextExercise ", this.nextExercise);
    },
    // test(){
    //   let otp = document.querySelector('.missingContentClass');
    //   for(let pin of otp.children) {
    //       pin.onkeyup = function() {
    //           if(pin.nextElementSibling) {
    //               pin.nextElementSibling.focus();
    //           }
    //       }
    //   }
    // },
    submitAnswer() {
      let str1 = this.missingContent;
      let substr = "_";
      let index = str1.indexOf(substr);
      while (index !== -1) {
        index = str1.indexOf(substr, index + 1);
      }
      var ans = [];
      let valCount = 0;
      let totalCount = 0;
      document.querySelectorAll(".emptyspace").forEach((element) => {
        if (element.value == "") {
          valCount++;
        }
        ans.push(element.value);
        totalCount++;
      });
      const data = {};
      data["exercise_id"] = 1;
      data["missing_characters"] = ans;
      StoryTimeService.submitExerciseAnswer(
        this.$route.params.storytime_id,
        data
      ).then((response) => {
        console.log(response);
        this.practice_complete = response.data.completed_storytime;
        this.exercise_complete = response.data.completed_exercise;
        this.correct_answer = response.data.valid_answer;
        if (this.correct_answer && !this.exercise_complete) {
          this.isAnswerRight = true;
          this.isAnswerWrong = false;
        }
        if (!this.correct_answer) {
          this.isAnswerRight = false;
          this.isAnswerWrong = true;
        }
        if (this.exercise_complete) {
          this.isExerciseCompleted = true;
          this.isAnswerWrong = false;
          this.isAnswerRight = true;
          // this.loadQuestion();
        }
      });
      console.log(valCount + " - " + totalCount);
    },
  },
};
</script>
