<template>
  <div class="course_page bg_7 position-relative">
    <AppHeader></AppHeader>
    Congrats
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
export default {
  name: "StoryExerciseView",
  components: { AppFooter, AppHeader },
};
</script>
