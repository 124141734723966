import { useToast } from "vue-toastification";
const toast = useToast();

class ToastService {
  toast() {
    return toast;
  }
}

export default new ToastService();
