import axios from "axios";

class QuizService {
  getQuiz(quizId) {
    return axios.get(
      process.env.VUE_APP_API + "/explanation/get_explanation_quiz/" + quizId
    );
  }
  submitQuiz(quizId, data) {
    return axios.post(
      process.env.VUE_APP_API +
        "/explanation/submit_explanation_quiz/" +
        quizId +
        "/",
      JSON.stringify(data)
    );
  }
}
export default new QuizService();
