<template>
  <router-link
    v-if="this.$store.state.auth.status.loggedIn && this.visibleBackButton"
    class="backbutton"
    :to="{
      name: 'lesson',
      params: {
        unit_id: this.$route.params.lesson_id,
      },
    }"
    ><i class="fas fa-angle-left"></i
  ></router-link>

  <div class="sidemenubar">
    <i
      v-if="this.$store.state.auth.status.loggedIn"
      @click="visibleLeft = true"
      class="pi pi-bars"
      style="font-size: 2rem"
    ></i>

    <SideBar v-model:visible="visibleLeft" :baseZIndex="10000">
      <div class="profile">
        <img src="@/assets/profile_img2.png" />
        <h4>
          {{ this.$store.state.auth.profile?.first_name }}
          {{ this.$store.state.auth.profile?.last_name }}
        </h4>
      </div>
      <ul class="nav flex-column">
        <li
          class="nav-item"
          :class="this.$route.name == 'profile' ? 'active' : ''"
        >
          <router-link to="/profile" class="nav-link">Profile</router-link>
        </li>
        <li
          class="nav-item"
          :class="this.$route.name == 'my-courses' ? 'active' : ''"
        >
          <router-link to="/my-courses" class="nav-link"
            >My courses</router-link
          >
        </li>
        <li
          class="nav-item"
          :class="this.$route.name == 'my-courses' ? active : ''"
        >
          <a
            @click="logout"
            v-if="this.$store.state.auth.status.loggedIn"
            href="javascript:;"
            class="nav-link"
            >Logout</a
          >
        </li>
      </ul>
    </SideBar>
  </div>
  <footer class="footer">
    <div class="container text-center">
      <!-- <router-link to="/reset-password-link">Reset password</router-link> -->
      <!-- <router-link to="/reset-password">Reset password</router-link> -->
      <router-link to="/contact">Contact</router-link>
      <a href="#">Terms & Conditions</a>
      <a href="#">Privacy Policy</a>
      <a href="#">Support </a>
      <a
        @click="logout"
        v-if="this.$store.state.auth.status.loggedIn"
        href="javascript:;"
        >Logout</a
      >
      <a v-if="!this.$store.state.auth.status.loggedIn" href="/">Login</a>
    </div>
  </footer>
</template>
<script>
import { useRoute } from "vue-router";
export default {
  name: "AppFooter",
  components: {},
  data() {
    return {
      visibleBackButton: false,
      visibleLeft: false,
    };
  },
  mounted() {
    const route = useRoute();
    if (Object.keys(route.params).length > 0) {
      this.visibleBackButton = true;
    } else {
      this.visibleBackButton = false;
    }
  },
  methods: {
    goBack() {
      console.log("this.$router.params : ", this.$router);
      this.$router.go(-1);
    },
    logout() {
      this.userPassError = false;
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/");
      });
    },
  },
};
</script>
