<template>
  <div class="exercise bg_2 h_100 position-relative">
    <AppHeader></AppHeader>
    <!-- <div class="childer-imgs">
      <div class="chil_img1">
        <img src="@/assets/pencil_child.png" />
      </div>
      <div class="chil_img2">
        <img src="@/assets/laptop_girl.png" />
      </div>
      <div class="chil_img3">
        <img src="@/assets/half_book.png" />
      </div>
      <div class="chil_img4">
        <img src="@/assets/book_readign.png" />
      </div>
    </div> -->
    <div class="container">
      <div class="pb-5">
        <div class="heading-lg justify-content-center d-flex text-center">
          <h1>EXERCISE 1</h1>
        </div>
        <div class="girl_section mx-auto">
          <div class="row h-100">
            <div class="col-sm-6" v-if="!isExerciseCompleted">
              <div class="qut-img">
                <img class="w-100" src="@/assets/girl_1.png" />
              </div>
            </div>
            <div
              class="col-sm-6 d-flex justify-content-center text-center align-items-center exercise1"
              v-if="!isExerciseCompleted"
            >
              <div class="py-3">
                <h4 class="">{{ question.text }}</h4>
                <div v-if="listen">
                  <div class="left-hand">
                    <div class="hands"></div>
                    <img
                      class="my-4"
                      @click.prevent="playSound(question.sound)"
                      width="93"
                      src="@/assets/volume.png"
                    />
                  </div>
                  <p>
                    <!-- {{ questions[questionIndex].source_sentence }} -->
                  </p>
                </div>
                <div v-else>
                  <div class="bottom-hand">
                    <div class="hands"></div>
                  </div>
                  <div
                    v-for="answer in answers"
                    :key="answer.id"
                    class="ans"
                    :class="
                      answer.id == answerId
                        ? isAnswerRight
                          ? 'right_ans'
                          : isAnswerWrong
                          ? 'wrong_ans'
                          : ''
                        : ''
                    "
                  >
                    <!-- <i class="far fa-smile" v-if="isAnswerRight"></i>
                    <i class="far fa-angry" v-if="isAnswerWrong"></i> -->
                    <img
                      class="fa-smile"
                      width="40"
                      src="@/assets/smile.svg"
                      v-if="isAnswerRight"
                    />
                    <img
                      class="fa-angry"
                      width="40"
                      src="@/assets/devil.svg"
                      v-if="isAnswerWrong"
                    />
                    <a
                      href="javascript:;"
                      @click="checkAnswer(answer.id)"
                      v-if="!isAnswerDisabled"
                    >
                      {{ answer.text }}
                    </a>
                    <a class="lock-box" v-else>{{ answer.text }}</a>
                  </div>

                  <div class="text-center" v-if="isAnswerRight">
                    <span class="leftanimat">
                      <img class="" width="39" src="@/assets/lefthand.svg" />
                    </span>
                    <button
                      v-if="isAnswerRight"
                      class="bg-transparent border-0 mt-3 next-btn"
                      @click="submitAndNextQue()"
                      v-bind:class="addBikeSpeedActive == false ? '' : 'active'"
                    >
                      <!-- <span>Next</span> -->
                      <img width="73" src="@/assets/motarbike.svg" />
                    </button>
                  </div>
                  <!-- <div class="ans right_ans">
                    <i class="far fa-smile"></i>
                    <a
                      href="javascript:;"
                      @click="checkAnswer(questions[questionIndex].answer1)"
                    >
                      {{ questions[questionIndex].answer1 }}
                    </a>
                  </div>
                  <div class="ans wrong_ans">
                    <i class="far fa-angry"></i>
                    <a
                      href="javascript:;"
                      @click="checkAnswer(questions[questionIndex].answer2)"
                    >
                      {{ questions[questionIndex].answer2 }}
                    </a>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="practice-exercise-procces" v-if="isExerciseCompleted">
            <div
              class="col-sm-12 conten flex-column justify-content-center align-items-center d-flex"
            >
              <button
                class="btn texture_btn btn-success btn-sm cancel_texture w-50 mb-4"
                @click="$router.go($router.currentRoute)"
              >
                <span> Try Again </span>
              </button>
              <router-link
                class="btn texture_btn btn-info btn-sm heart_texture w-50 align-items-center justify-content-center d-flex"
                :to="{
                  name: 'practice-exercise-' + nextExercise,
                  params: {
                    lesson_id: this.$route.params.lesson_id,
                    practice_id: this.$route.params.practice_id,
                  },
                }"
              >
                <span> Next Exercise </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppExcersiceFooter.vue";
import PractiseService from "../../services/practise.service";
import { getNextPractice } from "../../helpers/GetNextPractice";
//import ToastService from "@/services/toast.service";
export default {
  name: "Exercise1View",
  components: { AppFooter, AppHeader },
  data() {
    return {
      loading: false,
      listen: true,
      questionIndex: 0,
      question: {},
      answers: [],
      practice_complete: false,
      exercise_complete: false,
      correct_answer: "",
      isAnswerWrong: false,
      isAnswerRight: false,
      answerId: "",
      isAnswerDisabled: false,
      isExerciseCompleted: false,
      nextExercise: "",
      addBikeSpeedActive: false,
    };
  },
  mounted() {
    this.loadQuestion();
  },
  methods: {
    async loadQuestion() {
      const data = {};
      data["exercise"] = 1;
      let response = await PractiseService.getExerciseContent(
        this.$route.params.practice_id,
        data
      );
      this.question = await response.data.question;
      this.answers = await response.data.answers;
      this.questionIndex = await response.data.current_index;
      this.loading = false;
      this.isAnswerRight = false;
      this.isAnswerDisabled = false;
      this.addBikeSpeedActive = false;
      this.nextExercise = await getNextPractice(
        this.$route.params.lesson_id,
        this.$route.params.practice_id,
        1
      );
      console.log(this.nextExercise);
      // this.nextExercise = data["exercise"] + 1;
    },
    submitAndNextQue() {
      this.addBikeSpeedActive = true;
      this.loadQuestion();
    },
    playSound(sound) {
      let that = this;
      if (sound) {
        var audio = new Audio(sound);
        audio.play();
        that.listen = false;
        // setTimeout(function () {
        //   that.listen = false;
        // }, that.questions[that.questionIndex].audio_duration * 1000);
      }
    },
    playAudio() {
      this.listen = false;
    },
    checkAnswer(answerId) {
      const data = {};
      data["exercise"] = 1;
      data["answer_id"] = answerId;

      PractiseService.submitExerciseAnswer(
        this.$route.params.practice_id,
        data
      ).then((response) => {
        this.practice_complete = response.data.practice_complete;
        this.exercise_complete = response.data.exercise_complete;
        this.correct_answer = response.data.correct_answer;
        this.answerId = answerId;
        this.isAnswerDisabled = true;
        if (this.correct_answer && !this.exercise_complete) {
          this.isAnswerRight = true;
        }

        if (!this.correct_answer) {
          this.isAnswerWrong = true;
          setTimeout(
            () => (
              (this.isAnswerDisabled = false), (this.isAnswerWrong = false)
            ),
            3000
          );
        }

        if (this.exercise_complete) {
          this.isExerciseCompleted = true;
        }

        if (this.practice_complete) {
          this.$router.push({
            name: "practicings",
            params: { lesson_id: this.$route.params.lesson_id },
          });
        }
      });

      // if (answer == this.questions[this.questionIndex].target_sentence) {
      //   ToastService.toast().success("Successfully.");
      //   this.questionIndex++;
      //   this.listen = true;
      // } else {
      //   ToastService.toast().error("Try again!");
      // }
    },
  },
};
</script>
