import axios from "axios";

class AuthService {
  login(user) {
    let username = user.username;
    let password = user.password;
    let usernameBase64 = window.btoa(username);
    let passwordBase64 = window.btoa(password);
    return axios
      .get(process.env.VUE_APP_API + "/test_authentication/", {
        headers: {
          Authorization: usernameBase64 + ":" + passwordBase64,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          localStorage.setItem(
            "user",
            JSON.stringify({
              username: username,
              password: password,
              usernameBase64: usernameBase64,
              passwordBase64: passwordBase64,
            })
          );
        }
        return {
          username,
          password,
          usernameBase64,
          passwordBase64,
        };
      });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("profile");
  }
}

export default new AuthService();
