import axios from "axios";

class PractiseService {
  getPractise(lessonId) {
    return axios.get(
      process.env.VUE_APP_API + "/practice/get_practices/" + lessonId + "/"
    );
  }

  getExerciseContent(practiseId, data) {
    return axios.post(
      process.env.VUE_APP_API +
        "/practice/get_practice_exercise_content/" +
        practiseId +
        "/",
      JSON.stringify(data)
    );
  }

  submitExerciseAnswer(practiseId, data) {
    return axios.post(
      process.env.VUE_APP_API +
        "/practice/submit_exercise_answer/" +
        practiseId +
        "/",
      JSON.stringify(data)
    );
  }
}
export default new PractiseService();
