import axios from "axios";

class ExplanationService {
  getExplanations(lessonId) {
    return axios.get(
      process.env.VUE_APP_API + "/explanation/get_explanations/" + lessonId
    );
  }

  getExplanationsContent(explanationId) {
    return axios.get(
      process.env.VUE_APP_API +
        "/explanation/get_explanation_details/" +
        explanationId
    );
  }
}
export default new ExplanationService();
