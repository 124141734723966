<template>
  <div class="storypage bg_2 position-relative">
    <AppHeader></AppHeader>
    <div class="container">
      <div class="">
        <div class="heading-lg text-center">
          <h1 class="heading_2">EXERCISE 6</h1>
        </div>

        <div class="girl_section mx-auto">
          <div class="bg-white px-4 storyexercise6">
            <div class="row">
              <p class="heading_sm_2 mt-3 col-12 text-dark">
                Type the missing letters to complete the story
              </p>
              <div class="col-sm-12 text-center my-3">
                <img class="" width="184" src="@/assets/girlschild.png" />
              </div>
              <div class="text-center col-sm-12 mb-3">
                <img v-if="isAnswerWrong" width="75" src="@/assets/devil.svg" />
                <img
                  v-if="isAnswerRight"
                  class="me-2"
                  width="75"
                  src="@/assets/smile.svg"
                />
              </div>
              <div
                class="col-sm-12 d-flex justify-content-center text-center align-items-center"
              >
                <div class="construct-sen mb-4">
                  <div class="conten-label">
                    <a
                      class="btn btn-sm"
                      v-for="(target, index) in answers"
                      :key="target"
                      @drop="drop($event, index)"
                      @dragover="allowDrop($event)"
                      v-bind:id="'div' + index"
                      >{{ target }}</a
                    >
                  </div>
                  <div class="conten-label-2">
                    <a
                      class="btn btn-sm"
                      v-for="(question, index) in questionList"
                      :key="index"
                      :data-type="index"
                      v-bind:id="'drag' + index"
                      :draggable="inputDisabled == false"
                      @dragstart="drag($event)"
                      >{{ question }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="my-2 text-center">
            <button
              v-if="isAnswerWrong"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              @click="$router.go($router.currentRoute)"
            >
              <span class="shad"></span><span>Try Again</span>
            </button>
            <button
              v-if="!isAnswerRight && !isExerciseCompleted && !isAnswerWrong"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              @click="submitAnswer"
            >
              <span class="shad"></span><span>Check</span>
            </button>

            <button
              v-else-if="isAnswerRight && !isExerciseCompleted"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              @click="submitAnswer"
            >
              <span class="shad"></span><span>Next</span>
            </button>

            <router-link
              v-else-if="isAnswerRight && isExerciseCompleted"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              :to="{
                name: 'story-exercise-' + nextExercise,
                params: {
                  lesson_id: this.$route.params.lesson_id,
                  storytime_id: this.$route.params.storytime_id,
                },
              }"
              ><span class="shad"></span><span>Next Exercise</span></router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppExcersiceFooter.vue";
import StoryTimeService from "../../services/story-time.service.js";
import { getNextStoryTime } from "../../helpers/GetNextStoryTime.js";
export default {
  name: "StoryExercise6View",
  components: { AppFooter, AppHeader },
  data() {
    return {
      loading: false,
      lessons: null,
      exercise_id: 6,
      practice_complete: false,
      exercise_complete: false,
      correct_answer: "",
      isAnswerWrong: false,
      isAnswerRight: false,
      isAnswerDisabled: false,
      isExerciseCompleted: false,
      nextExercise: "",
      questionList: [],
      answers: [],
      word: "",
      checkAnswerForSubmit: [],
      answerForSubmit: [],
      checkboxChecked: false,
      recheckButton: false,
      content: ["there", "sir!", "Hello"],
      inputDisabled: false,
      queFirst: "",
      ansDrop: "",
    };
  },
  mounted() {
    this.loadQuestion();
  },
  methods: {
    async loadQuestion() {
      const data = {};
      this.answerForSubmit = [];
      this.loading = false;
      this.isAnswerRight = false;
      this.isAnswerDisabled = false;
      this.checkboxChecked = false;
      this.recheckButton = false;
      this.inputDisabled = false;
      this.word = "";
      this.queFirst = "";
      this.ansDrop = "";
      this.checkAnswerForSubmit = [];
      this.answers = [];
      data["exercise_id"] = this.exercise_id;
      let response = await StoryTimeService.getExerciseContent(
        this.$route.params.storytime_id,
        data
      );
      this.questionList = response.data.content;
      let que = [];
      que = this.questionList;
      que.forEach(() => {
        this.answers.push(" ");
      });

      this.nextExercise = await getNextStoryTime(
        this.$route.params.lesson_id,
        this.$route.params.storytime_id,
        1
      );
      this.nextExercise = data["exercise_id"] + 1;
    },

    allowDrop(ev) {
      ev.preventDefault();
    },

    drag(ev) {
      this.word = "";
      ev.dataTransfer.setData("text", ev.target.id);
    },

    drop(ev, indexNo) {
      ev.preventDefault();
      // var data = ev.dataTransfer.getData("text");
      // ev.target.appendChild(document.getElementById(data));

      var data = ev.dataTransfer.getData("text");
      ev.dataTransfer.setData("text", "");
      ev.target.appendChild(document.getElementById(data));
      this.ansDrop = "";
      this.ansDrop = ev.target.innerText;
      this.word = ev.srcElement.dataset.queelem;
      // console.log(this.ansDrop);
      // New Change
      // this.word = ev.srcElement.dataset.queelem;
      // this.queFirst = "";
      // this.queFirst = this.word;
      // if (this.ansDrop != "") {
      //   this.submitButton = false;
      //   // this.submitButton = true;
      //   this.inputDisabled = true;
      //   this.answerHideAfterSet = true;
      //   // this.setWord();
      //   console.log(this.answers);
      // }

      if (this.ansDrop) {
        this.answers[indexNo] = this.ansDrop;
        if (this.checkAnswerForSubmit.includes(indexNo) !== true) {
          this.checkAnswerForSubmit.push(indexNo);
        }
        this.word = false;
      } else {
        this.answers[indexNo] = "";
        this.word = false;
      }
      if (this.checkAnswerForSubmit.length == this.answers.length) {
        // this.nextButton = true;
        // this.submitButton = true;
      }
      console.log(this.answers);
    },

    selectWord(word) {
      this.word = word;
    },
    // setWord(indexNo) {
    //   if (this.word) {
    //     this.answers[indexNo] = this.word;
    //     if (this.checkAnswerForSubmit.includes(indexNo) !== true) {
    //       this.checkAnswerForSubmit.push(indexNo);
    //     }
    //     this.word = false;
    //   } else {
    //     this.answers[indexNo] = "";
    //     this.word = false;
    //   }
    //   if (this.checkAnswerForSubmit.length == this.answers.length) {
    //     // this.nextButton = true;
    //     // this.submitButton = true;
    //   }
    // },
    submitAnswer() {
      const data = {};
      data["exercise_id"] = this.exercise_id;
      data["answer"] = this.answers;
      StoryTimeService.submitExerciseAnswer(
        this.$route.params.storytime_id,
        data
      ).then((response) => {
        console.log(response);
        this.practice_complete = response.data.completed_storytime;
        this.exercise_complete = response.data.completed_exercise;
        this.correct_answer = response.data.valid_answer;
        if (this.correct_answer && !this.exercise_complete) {
          this.isAnswerRight = true;
          this.isAnswerWrong = false;
        }
        if (!this.correct_answer) {
          this.isAnswerRight = false;
          this.isAnswerWrong = true;
        }
        if (this.exercise_complete) {
          this.isExerciseCompleted = true;
          this.isAnswerWrong = false;
          this.isAnswerRight = true;
        }
      });
    },
  },
};
</script>
