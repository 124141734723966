<template>
  <div class="unit_page bg_4 position-relative h_100">
    <AppHeader></AppHeader>
    <div class="container">
      <div class="">
        <div class="heading-lg justify-content-center d-flex text-center">
          <h1><img width="63" src="../assets/pencil.png" />Units</h1>
          <!-- <p>{{ $t("hello") }}</p> -->
        </div>
        <div class="unit_section">
          <div
            class="col-sm-11 col-lg-8 col-xl-8 col-xxl-8 col-10 col-md-8 mx-auto"
          >
            <div class="row">
              <Suspense>
                <template #default>
                  <UnitCard :course_id="this.$route.params.course_id" />
                </template>
                <template #fallback>
                  <AppLoading />
                </template>
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
import AppLoading from "@/components/AppLoading.vue";
import UnitCard from "@/components/UnitCard.vue";
export default {
  name: "UnitsView",
  data() {
    return {
      loading: false,
      units: null,
    };
  },
  components: { AppFooter, AppHeader, AppLoading, UnitCard },
};
</script>
