<template>
  <div class="exercise bg_2 h_100 position-relative">
    <AppHeader></AppHeader>
    <!-- <div class="childer-imgs">
      <div class="chil_img1">
        <img src="@/assets/pencil_child.png" />
      </div>
      <div class="chil_img2">
        <img src="@/assets/laptop_girl.png" />
      </div>
      <div class="chil_img3">
        <img src="@/assets/half_book.png" />
      </div>
      <div class="chil_img4">
        <img src="@/assets/book_readign.png" />
      </div>
    </div> -->
    <div class="container">
      <div class="pb-5">
        <div class="heading-lg justify-content-center d-flex text-center">
          <h1>EXERCISE 3</h1>
        </div>
        <div class="girl_section mx-auto">
          <div class="row" v-if="!isExerciseCompleted">
            <div class="col-sm-6">
              <div class="qut-img">
                <img class="w-100" src="@/assets/girl_1.png" />
              </div>
            </div>
            <div
              class="col-sm-6 d-flex justify-content-center text-center align-items-center exercise3"
            >
              <div class="py-3 me-5 ms-4">
                <div v-if="!isExerciseCompleted">
                  <div class="text-center my-4 drag-heading">
                    <p class="mb-0">Please click on box</p>
                    <span>Customize your columns below</span>
                  </div>
                  <div
                    class="d-flex flex-wrap questions justify-content-center"
                  >
                    <div
                      class="questions_item"
                      v-for="source in question"
                      :key="source"
                      @click="selectWord(source)"
                      v-bind:style="{ backgroundColor: generateRandomColor() }"
                    >
                      <div>
                        {{ source }}
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <div class="bottom-hand">
                      <div class="hands"></div>
                    </div>
                  </div>
                  <div>
                    <img
                      class="fa-smile mb-4"
                      width="70"
                      src="@/assets/smile.svg"
                      v-if="isAnswerRight"
                    />

                    <img
                      class="fa-angry mb-4"
                      width="70"
                      src="@/assets/devil.svg"
                      v-if="isAnswerWrong"
                    />
                  </div>
                  <div class="ans-drop flex-wrap d-flex justify-content-center">
                    <div
                      class="drop-iteam"
                      :class="word ? 'answer_put' : ''"
                      v-for="(target, index) in answers"
                      :key="target"
                      @click="setWord(index)"
                    >
                      {{ target }}
                    </div>
                  </div>
                  <div class="text-center" v-if="submitButton">
                    <span class="leftanimat">
                      <img class="" width="39" src="@/assets/lefthand.svg" />
                    </span>
                    <button
                      class="bg-transparent border-0 mt-3 next-btn"
                      @click="checkAnswer()"
                      v-bind:class="addBikeSpeedActive == false ? '' : 'active'"
                    >
                      <!-- <span>Submit</span> -->
                      <img width="73" src="@/assets/motarbike.svg" />
                    </button>
                  </div>
                  <div class="text-center" v-if="nextButton">
                    <span class="leftanimat">
                      <img class="" width="39" src="@/assets/lefthand.svg" />
                    </span>
                    <button
                      class="bg-transparent border-0 mt-3 next-btn"
                      @click="nextQuestionClick()"
                      v-bind:class="addBikeSpeedActive == false ? '' : 'active'"
                    >
                      <!-- <span>Next</span> -->
                      <img width="73" src="@/assets/motarbike.svg" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-sm-12" v-if="isExerciseCompleted">
              <button
                class="btn texture_btn btn-success btn-sm circle_texture text-black mt-4"
                @click="$router.go($router.currentRoute)"
              >
              <span>  Try Again </span>
              </button>
              <div
                class="btn texture_btn btn-success btn-sm circle_texture text-black mt-4"
              >
                <router-link
                  :to="{
                    name: 'practice-exercise-' + nextExercise,
                    params: {
                      lesson_id: this.$route.params.lesson_id,
                      practice_id: this.$route.params.practice_id,
                    },
                  }"
                  > <span> Next Exercise </span></router-link
                >
              </div>
            </div> -->
          </div>

          <div class="practice-exercise-procces" v-if="isExerciseCompleted">
            <div
              class="col-sm-12 conten flex-column justify-content-center align-items-center d-flex"
            >
              <button
                class="btn texture_btn btn-success btn-sm cancel_texture w-50 mb-4"
                @click="$router.go($router.currentRoute)"
              >
                Try Again
              </button>
              <router-link
                class="btn texture_btn btn-info btn-sm heart_texture w-50 align-items-center justify-content-center d-flex"
                :to="{
                  name: 'practice-exercise-' + nextExercise,
                  params: {
                    lesson_id: this.$route.params.lesson_id,
                    practice_id: this.$route.params.practice_id,
                  },
                }"
              >
                Next Exercise
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppExcersiceFooter.vue";
// import ToastService from "@/services/toast.service";
import { BgColors } from "@/constants/BgColors";
import PractiseService from "../../services/practise.service";
import { getNextPractice } from "../../helpers/GetNextPractice";

export default {
  name: "Exercise3View",
  components: { AppFooter, AppHeader },
  data() {
    return {
      loading: false,
      word: false,
      bgColors: BgColors,

      questionIndex: 0,
      question: {},
      answers: [],
      checkAnswerForSubmit: [],
      practice_complete: false,
      exercise_complete: false,
      correct_answer: false,
      isAnswerRight: false,
      isAnswerWrong: false,
      answerId: "",
      isAnswerDisabled: false,
      isExerciseCompleted: false,
      nextButton: false,
      submitButton: false,
      nextExercise: "",
      addBikeSpeedActive: false,
      listen: true,
      // questionIndex: 0,
      // questions: [
      //   {
      //     id: 1,
      //     sources: ["CAT", "TABLE", "IS", "THE", "THE", "UNDER"],
      //     targets: ["", "", "", "", "", ""],
      //     image: "#",
      //   },
      //   {
      //     id: 2,
      //     sources: ["CAT", "TABLE", "IS", "THE", "THE", "UNDER"],
      //     targets: ["", "", "", "", "", ""],
      //     image: "#",
      //   },
      //   {
      //     id: 3,
      //     sources: ["CAT", "TABLE", "IS", "THE", "THE", "UNDER"],
      //     targets: ["", "", "", "", "", ""],
      //     image: "#",
      //   },
      // ],
    };
  },
  mounted() {
    this.loadQuestion();
  },
  methods: {
    async loadQuestion() {
      this.question = {};
      this.answers = [];
      this.checkAnswerForSubmit = [];
      const data = {};
      data["exercise"] = 3;
      let response = await PractiseService.getExerciseContent(
        this.$route.params.practice_id,
        data
      );
      this.question = await response.data.answers;
      let que = [];
      que = this.question;
      // let ansTarget = [];
      que.forEach(() => {
        this.answers.push(" ");
      });
      // this.answers = ansTarget;
      this.loading = false;
      this.isAnswerRight = false;
      this.isAnswerWrong = false;
      this.isAnswerDisabled = false;
      this.nextButton = false;
      this.submitButton = false;
      this.nextExercise = await getNextPractice(
        this.$route.params.lesson_id,
        this.$route.params.practice_id,
        3
      );
      console.log(this.nextExercise);
      // this.nextExercise = data["exercise"] + 1;
      this.addBikeSpeedActive = false;
    },
    selectWord(word) {
      this.word = word;
    },
    setWord(indexNo) {
      if (this.word) {
        this.answers[indexNo] = this.word;
        if (this.checkAnswerForSubmit.includes(indexNo) !== true) {
          this.checkAnswerForSubmit.push(indexNo);
        }
        this.word = false;
      } else {
        this.answers[indexNo] = "";
        this.word = false;
      }
      // console.log(this.answers);
      // console.log(this.checkAnswerForSubmit);
      if (this.checkAnswerForSubmit.length == this.answers.length) {
        // this.nextButton = true;
        this.submitButton = true;
      }
    },

    checkAnswer() {
      this.addBikeSpeedActive = true;
      const data = {};
      data["exercise"] = 3;
      data["answer"] = this.answers.join(" ");

      PractiseService.submitExerciseAnswer(
        this.$route.params.practice_id,
        data
      ).then((response) => {
        console.log(response);
        this.addBikeSpeedActive = false;
        this.practice_complete = response.data.practice_complete;
        this.exercise_complete = response.data.exercise_complete;
        this.correct_answer = response.data.correct_answer;
        this.submitButton = false;
        this.nextButton = true;
        // this.isAnswerDisabled = true;
        if (this.correct_answer && !this.exercise_complete) {
          this.isAnswerRight = true;
        }

        if (!this.correct_answer) {
          this.isAnswerWrong = true;
        }

        // this.nextButton = true;

        // if (this.exercise_complete) {
        //   this.nextButton = false;
        //   this.isExerciseCompleted = true;
        // }

        // if (this.practice_complete) {
        //   this.nextButton = false;
        //   this.$router.push({
        //     name: "practicings",
        //     params: { lesson_id: this.$route.params.lesson_id },
        //   });
        // }
      });
      // if (answer == this.questions[this.questionIndex].source_sentence) {
      //   ToastService.toast().success("Successfully.");
      //   this.questionIndex++;
      // } else {
      //   ToastService.toast().error("Try again!");
      // }
    },

    nextQuestionClick() {
      this.addBikeSpeedActive = true;
      this.isAnswerRight = false;
      this.isAnswerWrong = false;
      this.nextButton = true;

      if (this.exercise_complete) {
        this.nextButton = false;
        this.isExerciseCompleted = true;
      } else {
        this.loadQuestion();
      }

      if (this.practice_complete) {
        this.nextButton = false;
        this.$router.push({
          name: "practicings",
          params: { lesson_id: this.$route.params.lesson_id },
        });
      }
    },
    generateRandomColor() {
      let randomIndex = Math.floor(Math.random() * this.bgColors.length);
      return this.bgColors[randomIndex];
    },
  },
};
</script>
