<template>
  <div class="exercise bg_2 h_100 position-relative">
    <AppHeader></AppHeader>

    <div class="container">
      <div class="py-5">
        <div class="heading-lg justify-content-center d-flex text-center">
          <h1>EXERCISE 6</h1>
        </div>
        <div class="girl_section mx-auto">
          <div class="row">
            <div
              class="exercise6 col-sm-12 d-flex justify-content-center text-center align-items-center"
              v-if="!isExerciseCompleted"
            >
              <div class="py-3">
                <div class="text-center my-4 drag-heading">
                  <p class="mb-0">Drag and Drop</p>
                  <span>Customize your columns below</span>
                </div>
                <div v-if="!isExerciseCompleted">
                  <div
                    class="d-flex flex-wrap questions justify-content-center"
                  >
                    <div
                      v-for="(source, index) in question"
                      :key="index"
                      :disabled="inputDisabled == true"
                    >
                      <div
                        class="questions_item"
                        @mouseover="selectWord(source.text)"
                        :disabled="inputDisabled == true"
                      >
                        <div v-if="source.language == 'native'">
                          {{ source.text }}
                        </div>
                        <div
                          class="qn-drop"
                          v-bind:id="'div' + index"
                          :data-queElem="source.text"
                          :data-type="index"
                          @drop="drop($event)"
                          @dragover="allowDrop($event)"
                          v-bind:class="
                            source.result != '' && source.result == 'right'
                              ? 'rightClass'
                              : source.result != '' && source.result == 'wrong'
                              ? 'wrongClass'
                              : ''
                          "
                        ></div>
                      </div>
                    </div>
                  </div>
                  <!-- <div>
                    <img
                      class="fa-smile my-2"
                      width="70"
                      src="@/assets/smile.svg"
                      v-if="isAnswerRight"
                    />
                    <img
                      class="fa-angry my-2"
                      width="70"
                      src="@/assets/devil.svg"
                      v-if="isAnswerWrong"
                    />
                  </div> -->
                  <div class="">
                    <div class="bottom-hand">
                      <div class="hands"></div>
                    </div>
                  </div>
                  <div class="ans-drop flex-wrap d-flex justify-content-center">
                    <div
                      class="drop-iteam"
                      :class="word ? 'answer_put' : ''"
                      v-for="(target, index) in answers"
                      :key="target"
                      :data-type="index"
                      v-bind:id="'drag' + index"
                      :draggable="true"
                      @dragstart="drag($event)"
                    >
                      {{ target.text }}
                    </div>
                  </div>
                  <div class="text-center" v-if="submitButton">
                    <span class="leftanimat">
                      <img class="" width="39" src="@/assets/lefthand.svg" />
                    </span>
                    <button
                      class="bg-transparent border-0 mt-3 next-btn"
                      @click="nextQuestionClick()"
                      v-if="respAfterAllSubmit == true"
                    >
                      <!-- <span>Submit</span> -->
                      <img width="73" src="@/assets/motarbike.svg" />
                    </button>
                  </div>
                  <div class="text-center" v-if="nextButton">
                    <span class="leftanimat">
                      <img class="" width="39" src="@/assets/lefthand.svg" />
                    </span>
                    <button
                      class="bg-transparent border-0 mt-3 next-btn"
                      @click="nextQuestionClick()"
                    >
                      <!-- <span>Next</span> -->
                      <img width="73" src="@/assets/motarbike.svg" />
                    </button>
                  </div>
                  <div class="">
                    <div
                      class="next-text"
                      v-if="
                        showTextAfterResponse == true && isAnswerRight == true
                      "
                    >
                      Next
                    </div>
                    <div
                      class="angry-text"
                      v-if="
                        showTextAfterResponse == true && isAnswerWrong == true
                      "
                    >
                      Try Again
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="practice-exercise-procces" v-if="isExerciseCompleted">
            <div
              class="col-sm-12 conten flex-column justify-content-center align-items-center d-flex"
            >
              <button
                class="btn texture_btn btn-success btn-sm cancel_texture w-50 mb-4"
                @click="$router.go($router.currentRoute)"
              >
                <span> Try Again </span>
              </button>
              <router-link
                class="btn texture_btn btn-info btn-sm heart_texture w-50 align-items-center justify-content-center d-flex"
                :to="{
                  name: 'practice-exercise-' + nextExercise,
                  params: {
                    lesson_id: this.$route.params.lesson_id,
                    practice_id: this.$route.params.practice_id,
                  },
                }"
              >
                <span> Next Exercise </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>
<style>
.answerHideClass {
  display: none !important;
}
.wrongClass .answer_put {
  background: #ff0000 !important;
}
.rightClass .answer_put {
  background: #12d912 !important;
}
</style>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppExcersiceFooter.vue";
// import ToastService from "@/services/toast.service";
import { BgColors } from "@/constants/BgColors";
import PractiseService from "../../services/practise.service";
import { getNextPractice } from "../../helpers/GetNextPractice";

export default {
  name: "Exercise3View",
  components: { AppFooter, AppHeader },
  data() {
    return {
      loading: false,
      word: false,
      bgColors: BgColors,
      tt: [
        { text: "Book", language: "target" },
        { text: "Door", language: "target" },
        { text: "Ajtó", language: "native" },
        { text: "Szia", language: "native" },
      ],
      questionIndex: 0,
      question: {},
      answers: [],
      checkAnswerForSubmit: [],
      practice_complete: false,
      exercise_complete: false,
      correct_answer: false,
      isAnswerRight: false,
      isAnswerWrong: false,
      answerId: "",
      isAnswerDisabled: false,
      isExerciseCompleted: false,
      nextButton: false,
      submitButton: false,
      nextExercise: "",
      listen: true,
      que_submit_check: [],
      ans_submit_check: [],
      queFirst: "",
      ansDrop: "",
      inputDisabled: false,
      addBikeSpeedActive: false,
      answerHideAfterSet: false,
      showTextAfterResponse: false,
      storeQueAns: [],
      respAfterAllSubmit: false,
    };
  },
  mounted() {
    this.loadQuestion();
  },
  methods: {
    async loadQuestion() {
      this.question = {};
      this.answers = [];
      this.checkAnswerForSubmit = [];
      this.que_submit_check = [];
      this.ans_submit_check = [];
      this.queFirst = "";
      this.ansDrop = "";
      this.showTextAfterResponse = false;
      this.respAfterAllSubmit = false;
      const data = {};
      this.inputDisabled = false;
      this.answerHideAfterSet = false;
      data["exercise"] = 6;
      let response = await PractiseService.getExerciseContent(
        this.$route.params.practice_id,
        data
      );
      let resQue = await response.data.answers;
      // this.question = resQue.filter((v) => v.language == "native");
      this.question = resQue.filter((item) => {
        if (item.language === "native") {
          item.result = "";
          return item;
        }
      });
      this.answers = resQue.filter((v) => v.language == "target");
      this.loading = false;
      this.isAnswerRight = false;
      this.isAnswerWrong = false;
      this.isAnswerDisabled = false;
      this.nextButton = false;
      this.submitButton = false;
      this.nextExercise = await getNextPractice(
        this.$route.params.lesson_id,
        this.$route.params.practice_id,
        6
      );
      // this.nextExercise = data["exercise"] + 1;
      // console.log(this.nextExercise);
      this.addBikeSpeedActive = false;
      this.storeQueAns = [];
    },
    allowDrop(ev) {
      ev.preventDefault();
    },

    drag(ev) {
      this.word = "";
      ev.dataTransfer.setData("text", ev.target.id);
    },

    drop(ev) {
      ev.preventDefault();
      var data = ev.dataTransfer.getData("text");
      ev.dataTransfer.setData("text", "");
      ev.target.appendChild(document.getElementById(data));
      this.ansDrop = "";
      this.ansDrop = ev.target.innerText;

      // New Change
      this.word = ev.srcElement.dataset.queelem;
      this.queFirst = "";
      this.queFirst = this.word;
      if (this.ansDrop != "") {
        this.submitButton = false;
        // this.submitButton = true;
        this.inputDisabled = true;
        this.answerHideAfterSet = true;
        this.checkAnswer();
      }

      // console.log(ev.srcElement.dataset.queelem);
    },
    selectWord(word) {
      // console.log(this.word);
      this.word = word;
      this.queFirst = "";
      this.queFirst = word;
      if (this.ansDrop != "") {
        this.submitButton = false;
        // this.submitButton = true;
        this.inputDisabled = true;
        this.answerHideAfterSet = true;
      }
    },
    checkAnswer() {
      this.addBikeSpeedActive = true;
      const data = {};

      data["exercise"] = 6;
      data["answer_1"] = this.ansDrop;
      data["answer_2"] = this.queFirst;
      PractiseService.submitExerciseAnswer(
        this.$route.params.practice_id,
        data
      ).then((response) => {
        this.addBikeSpeedActive = false;
        this.queFirst = "";
        this.ansDrop = "";
        this.practice_complete = response.data.practice_complete;
        this.exercise_complete = response.data.exercise_complete;
        this.correct_answer = response.data.correct_answer;
        this.submitButton = false;
        // this.inputDisabled = false;
        this.nextButton = false;
        this.answerHideAfterSet = true;
        // this.isAnswerDisabled = true;
        if (this.correct_answer && !this.exercise_complete) {
          this.isAnswerRight = true;
          this.showTextAfterResponse = false;
          data["result"] = "right";
        }

        if (!this.correct_answer) {
          this.isAnswerWrong = true;
          this.showTextAfterResponse = false;
          data["result"] = "wrong";
        }
        if (this.exercise_complete) {
          this.isExerciseCompleted = true;
        }
        this.question.filter((item) => {
          if (item.text === data["answer_2"]) {
            item.result = data["result"];
          }
          return item;
        });
        this.storeQueAns.push(data);
        console.log(this.storeQueAns);
        var tt1 = 0;
        this.question.forEach((element) => {
          if (element.result != "") {
            tt1++;
          }
        });
        if (this.question.length == tt1) {
          console.log(42223);
          this.respAfterAllSubmit = true;
          this.submitButton = true;
        }
        console.log(this.question);
      });
    },

    nextQuestionClick() {
      this.addBikeSpeedActive = true;
      this.isAnswerRight = false;
      this.isAnswerWrong = false;
      this.nextButton = false;
      this.showTextAfterResponse = false;

      if (this.exercise_complete) {
        this.nextButton = false;
        this.isExerciseCompleted = true;
      } else {
        this.runNextQuestion();
      }

      if (this.practice_complete) {
        this.nextButton = false;
        this.$router.push({
          name: "practicings",
          params: { lesson_id: this.$route.params.lesson_id },
        });
      }
    },
    runNextQuestion() {
      this.loadQuestion();
    },
    generateRandomColor() {
      let randomIndex = Math.floor(Math.random() * this.bgColors.length);
      return this.bgColors[randomIndex];
    },
  },
};
</script>
