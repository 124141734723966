import StoryTimeService from "@/services/story-time.service";
export async function getNextStoryTime(lesson_id, storytime_id, exercise_id) {
  var storytimes = "";
  var currentstory = [];
  var nextExercise = "";
  let response = await StoryTimeService.getStoryTime(lesson_id);
  storytimes = await response.data.storytimes;
  currentstory = await storytimes.filter((storytime) => {
    if (storytime.id == storytime_id) {
      return storytime;
    }
  });
  nextExercise = await findNextExercise(currentstory, exercise_id);
  return nextExercise;
}

function findNextExercise(currentstory, exercise_id) {
  // console.log("currentstory ", currentstory);
  // console.log(currentstory[0].remaining_exercises.length);
  var currentIndex = 0;
  if (currentstory[0].remaining_exercises.length > 0) {
    currentIndex = currentstory[0].remaining_exercises.indexOf(exercise_id);
  } else {
    currentIndex = currentstory[0].completed_exercises.indexOf(exercise_id);
  }
  if (currentstory[0].remaining_exercises.length != currentIndex) {
    return currentstory[0].remaining_exercises[currentIndex + 1];
  } else if (currentstory[0].completed_exercises.length != currentIndex) {
    return currentstory[0].completed_exercises[currentIndex + 1];
  } else {
    return -1;
  }
}
