import axios from "axios";
import i18n from "@/i18n";
//import qs from "qs";

class UserService {
  getProfile() {
    return axios
      .get(process.env.VUE_APP_API + "/get_profile/")
      .then((response) => {
        if (response.status == 200) {
          let profile = response.data.profile;
          //console.log("profile : ", profile.native_language.name);
          //profile.from_language = "de";
          i18n.global.locale = profile.native_language.name;
          localStorage.setItem("profile", JSON.stringify(profile));
          return profile;
        }
        return {};
      });
  }

  updateProfile() {
    return axios.get(process.env.VUE_APP_API + "/get_profile/");
  }

  updateLanguage() {
    return axios.get(process.env.VUE_APP_API + "/get_profile/");
  }

  sendPasswordReset() {
    return axios.get(process.env.VUE_APP_API + "/get_profile/");
  }
  sendContactMessage(data) {
    return axios.post(
      process.env.VUE_APP_API + "/submit_contact_form/",
      new URLSearchParams(data)
    );
  }
  resetPasswordLink(data) {
    return axios.post(
      process.env.VUE_APP_API + "/request_password_reset/",
      new URLSearchParams(data)
    );
  }
  resetPassword(data) {
    return axios.post(
      process.env.VUE_APP_API + "/reset_password/",
      new URLSearchParams(data)
    );
  }
}

export default new UserService();
