<template>
  <div class="storypage bg_2 position-relative">
    <AppHeader></AppHeader>
    <div class="container">
      <div class="">
        <div class="heading-lg text-center">
          <h1 class="heading_2">EXERCISE 5</h1>
        </div>
        <div class="girl_section mx-auto">
          <div class="bg-white p-4 storyexercise5">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="mb-4 text-center">Question & Answers</h4>
                <div class="text-center my-3">
                  <img
                    v-if="isAnswerWrong"
                    width="75"
                    src="@/assets/devil.svg"
                  />
                  <img
                    v-if="isAnswerRight"
                    class="me-2"
                    width="75"
                    src="@/assets/smile.svg"
                  />
                </div>
                <form>
                  <div
                    class="qn-ans mb-4"
                    v-for="(question, index) in questionList"
                    :key="index"
                    id="questionListID"
                  >
                    <h6 class="mb-2">
                      {{ index + 1 }}) {{ question.statement }}?
                    </h6>
                    <div
                      class="custom-radio d-flex flex-column ps-1"
                      v-bind:id="'questionListID' + index"
                    >
                      <div
                        v-for="(option, opIndex) in question.options"
                        :key="opIndex"
                      >
                        <input
                          v-bind:id="'radio-' + opIndex + '_' + index"
                          class="radio-custom"
                          v-bind:name="'radio-group' + index"
                          type="radio"
                          v-on:change="radioChange($event)"
                        />
                        <label
                          v-bind:for="'radio-' + opIndex + '_' + index"
                          class="radio-custom-label"
                          >{{ option.text }}</label
                        >
                      </div>
                    </div>
                  </div>
                  <!-- <div class="qn-ans mb-4">
                    <h6 class="mb-2">1) Who is john?</h6>
                    <div class="custom-radio d-flex">
                      <img class="me-2" width="32" src="@/assets/devil.svg" />
                      <div>
                        <input
                          id="radio-1"
                          class="radio-custom"
                          name="radio-group"
                          type="radio"
                          checked
                        />
                        <label for="radio-1" class="radio-custom-label"
                          >A Writer</label
                        >
                      </div>
                      <div>
                        <input
                          id="radio-2"
                          class="radio-custom"
                          name="radio-group"
                          type="radio"
                        />
                        <label for="radio-2" class="radio-custom-label"
                          >A dog</label
                        >
                      </div>
                      <div>
                        <input
                          id="radio-3"
                          class="radio-custom"
                          name="radio-group"
                          type="radio"
                        />
                        <label for="radio-3" class="radio-custom-label"
                          >A baby</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="qn-ans mb-4">
                    <h6 class="mb-2">
                      2) what is the name of john's daughter?
                    </h6>
                    <div class="custom-radio d-flex">
                      <img class="me-2" width="32" src="@/assets/smile.svg" />
                      <div>
                        <input
                          id="radio-11"
                          class="radio-custom"
                          name="radio-group2"
                          type="radio"
                          checked
                        />
                        <label for="radio-11" class="radio-custom-label"
                          >Cacilia</label
                        >
                      </div>
                      <div>
                        <input
                          id="radio-21"
                          class="radio-custom"
                          name="radio-group2"
                          type="radio"
                        />
                        <label for="radio-21" class="radio-custom-label"
                          >Laura</label
                        >
                      </div>
                      <div>
                        <input
                          id="radio-31"
                          class="radio-custom"
                          name="radio-group2"
                          type="radio"
                        />
                        <label for="radio-31" class="radio-custom-label"
                          >Carly</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="qn-ans mb-4">
                    <h6 class="mb-2">3) Where is the shower?</h6>
                    <div class="custom-radio d-flex">
                      <img class="me-2" width="32" src="@/assets/devil.svg" />
                      <div>
                        <input
                          id="radio-12"
                          class="radio-custom"
                          name="radio-group3"
                          type="radio"
                          checked
                        />
                        <label for="radio-12" class="radio-custom-label"
                          >In the bedrooom</label
                        >
                      </div>
                      <div>
                        <input
                          id="radio-23"
                          class="radio-custom"
                          name="radio-group3"
                          type="radio"
                        />
                        <label for="radio-23" class="radio-custom-label"
                          >In the bathroom</label
                        >
                      </div>
                    </div>
                  </div> -->
                </form>
              </div>
            </div>
          </div>
          <div class="my-2 text-center">
            <button
              v-if="isAnswerWrong"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              @click="$router.go($router.currentRoute)"
            >
              <span class="shad"></span><span>Try Again</span>
            </button>
            <button
              v-if="!isAnswerRight && !isExerciseCompleted && !isAnswerWrong"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              @click="submitAnswer"
            >
              <span class="shad"></span><span>Check</span>
            </button>

            <button
              v-else-if="isAnswerRight && !isExerciseCompleted"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              @click="submitAnswer"
            >
              <span class="shad"></span><span>Next</span>
            </button>

            <router-link
              v-else-if="isAnswerRight && isExerciseCompleted"
              class="btn texture_btn btn-success btn-sm cancel_texture my_kids"
              :to="{
                name: 'story-exercise-' + nextExercise,
                params: {
                  lesson_id: this.$route.params.lesson_id,
                  storytime_id: this.$route.params.storytime_id,
                },
              }"
              ><span class="shad"></span><span>Next Exercise</span></router-link
            >
          </div>
          >
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppExcersiceFooter.vue";
import StoryTimeService from "../../services/story-time.service.js";
import { getNextStoryTime } from "../../helpers/GetNextStoryTime.js";
export default {
  name: "StoryExercise5View",
  components: { AppFooter, AppHeader },
  data() {
    return {
      loading: false,
      lessons: null,
      exercise_id: 5,
      practice_complete: false,
      exercise_complete: false,
      correct_answer: "",
      isAnswerWrong: false,
      isAnswerRight: false,
      isAnswerDisabled: false,
      isExerciseCompleted: false,
      nextExercise: "",
      questionList: [],
      answerForSubmit: [],
      checkboxChecked: false,
      recheckButton: false,
      content: [
        {
          statement: "Test Statement",
          index: 0,
          options: [
            {
              text: "correct",
              index: 1,
            },
            {
              text: "incorrect",
              index: 0,
            },
            {
              text: "Right",
              index: 2,
            },
          ],
        },
        {
          statement: "Test Statement 2",
          index: 1,
          options: [
            {
              text: "This is correct",
              index: 2,
            },
            {
              text: "This is dog",
              index: 1,
            },
            {
              text: "This is cat",
              index: 1,
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.loadQuestion();
  },
  methods: {
    async loadQuestion() {
      const data = {};
      this.answerForSubmit = [];
      this.loading = false;
      this.isAnswerRight = false;
      this.isAnswerDisabled = false;
      this.checkboxChecked = false;
      this.recheckButton = false;
      data["exercise_id"] = this.exercise_id;
      let response = await StoryTimeService.getExerciseContent(
        this.$route.params.storytime_id,
        data
      );
      console.log(response);
      this.questionList = response.data.content;
      // this.questionList = this.content;
      this.nextExercise = await getNextStoryTime(
        this.$route.params.lesson_id,
        this.$route.params.storytime_id,
        1
      );
      this.nextExercise = data["exercise_id"] + 1;
    },
    radioChange(e) {
      var checkboxId = e.target.id;
      var fields = checkboxId.split("-");
      var fieldsWithIndex = fields[1].split("_");
      if (this.answerForSubmit.length < this.questionList.length) {
        this.answerForSubmit.push(parseInt(fieldsWithIndex[1]));
      }

      for (var key in this.answerForSubmit) {
        if (parseInt(fieldsWithIndex[1]) == key) {
          if (e.target.checked == true) {
            this.answerForSubmit[key] = parseInt(fieldsWithIndex[0]);
          }
        }
      }
      console.log(this.answerForSubmit);
    },
    submitAnswer() {
      const data = {};
      data["exercise_id"] = this.exercise_id;
      data["answer"] = Object.assign({}, this.answerForSubmit);
      StoryTimeService.submitExerciseAnswer(
        this.$route.params.storytime_id,
        data
      ).then((response) => {
        console.log(response);
        this.practice_complete = response.data.completed_storytime;
        this.exercise_complete = response.data.completed_exercise;
        this.correct_answer = response.data.valid_answer;
        if (this.correct_answer && !this.exercise_complete) {
          this.isAnswerRight = true;
          this.isAnswerWrong = false;
        }
        if (!this.correct_answer) {
          this.isAnswerRight = false;
          this.isAnswerWrong = true;
        }
        if (this.exercise_complete) {
          this.isExerciseCompleted = true;
          this.isAnswerWrong = false;
          this.isAnswerRight = true;
          // this.loadQuestion();
        }
      });
    },
  },
};
</script>
