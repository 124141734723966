import axios from "axios";

class StoryTimeService {
  getStoryTime(lessonId) {
    return axios.get(
      process.env.VUE_APP_API + "/storytime/get_storytimes/" + lessonId + "/"
    );
  }

  getExerciseContent(storyId, data) {
    return axios.post(
      process.env.VUE_APP_API +
        "/storytime/get_storytime_exercise/" +
        storyId +
        "/",
      JSON.stringify(data)
    );
  }

  submitExerciseAnswer(storyId, data) {
    return axios.post(
      process.env.VUE_APP_API +
        "/storytime/submit_exercise_answer/" +
        storyId +
        "/",
      JSON.stringify(data)
    );
  }
}
export default new StoryTimeService();
