import PractiseService from "../services/practise.service";
export async function getNextPractice(lesson_id, practise_id, exercise_id) {
  var practices = "";
  var currentPractice = [];
  var nextExercise = "";
  let response = await PractiseService.getPractise(lesson_id);
  practices = await response.data.practices;
  currentPractice = await practices.filter((practice) => {
    if (practice.id == practise_id) {
      return practice;
    }
  });

  nextExercise = await findNextExercise(currentPractice, exercise_id);
  return nextExercise;
}

function findNextExercise(currentPractice, exercise_id) {
  var currentIndex = currentPractice[0].exercise_types.indexOf(exercise_id);

  if (currentPractice[0].exercise_types.length != currentIndex) {
    return currentPractice[0].exercise_types[currentIndex + 1];
  } else {
    return -1;
  }
}
