<template>
  <div class="course_page bg_7 position-relative h_100">
    <div class="top_area">
      <div class="container">
        <div class="brand_logo text-center">
          <img width="265" src="../assets/logo.png" />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="">
        <div class="heading-lg justify-content-center d-flex text-center">
          <h1><img width="63" src="../assets/pencil.png" /> My Courses</h1>
        </div>
        <div class="course_section">
          <div
            class="col-sm-11 col-lg-8 col-xl-6 col-xxl-6 col-10 col-md-8 mx-auto"
          >
            <div class="row">
              <Suspense>
                <template #default>
                  <CourseCard />
                </template>
                <template #fallback>
                  <AppLoading />
                </template>
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AppFooter></AppFooter>
</template>

<script>
import AppFooter from "@/components/AppFooter.vue";
import AppLoading from "@/components/AppLoading.vue";
import CourseCard from "@/components/CourseCard.vue";
export default {
  name: "MyCoursesView",
  data() {
    return {
      loading: false,
      courses: null,
    };
  },
  components: { AppFooter, AppLoading, CourseCard },
};
</script>
